//*****************************************************************************
//    Project: Twona
//    Title: _tw-text.scss
//    Description: Estilos de textos
//    Page: Generic
//******************************************************************************/
.tw-title{
	font-size: 40px;
	color: #000;
	font-weight: 100;
} 