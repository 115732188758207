/**
 * for files list
 **/

 /* file info */
 .twona-files-list-item {
     @extend .bg-c12-dark;
     height: 40px;
     width: 100%;
     position: relative;
     font-size: 14px; // not necesary
     font-weight: 300;

     .twona-files-list-item-name {
         margin-right: 80px; // for 2x40px square
     }

     .twona-files-list-item-name,
     .twona-files-list-item-progressbar-label,
     .twona-files-list-item-extension {
         line-height: 30px;
         padding: 5px 10px;
         white-space: nowrap; // para qe el nombre largo no se vaya a mas de una linea
         overflow: hidden; // oculta texto si es mas grande que 80% width
         text-overflow: ellipsis; // puntos suspensivos
     }

     .twona-files-list-item-btn,
     .twona-files-list-item-delete-btn,
     .twona-files-list-item-progressbar-cancel-btn {
         @extend .button;
         @extend .smallSquared;
         @extend .floatRight;
         @extend .bg-c11-light;
     }


     .twona-files-list-item-extension {
             @extend .smallSquared; //no funciona con button
             @extend .floatRight;
             @extend .bg-c11-dark;
             color: #eee;
             width: 30px;
     }

     //.twona-files-list-item-progressbar-container {
         .twona-files-list-item-progressbar {
             margin-right: 40px; // for cancel button

             &.ui-widget-content {
                font-family: $font-family-default;
                height: 40px;
                background-image: none;
                border-radius: 0px;
                // to negate .status-wrapper-right .ui-widget-content
                float: none;
                display: inherit;
                width: auto;
             }

             .twona-files-list-item-progressbar-label {
                 @extend .floatLeft;
             }

             .ui-progressbar-value {
                 //@extend .floatLeft;
                 @extend .bg-c4-dark;
                 border-radius: 0px;
                 background-image: none;
                 margin: 0px;
             }
         }
     //}

     // impar
     &:nth-child(even) {
         @extend .bg-c12-light;

         .twona-files-list-item-progressbar-container {
             .twona-files-list-item-progressbar {
                 .ui-progressbar-value {
                     @extend .bg-c4-light;
                 }
             }
         }
     }
 }

.files-table {
    .file-table-preview {
        background-image: url(../img/asset_info.png);
        background-position: 50% 80%;
        background-repeat: no-repeat;
        //font-weight: 700;
        p {
            color: #fff !important;
            font-weight: 700;
            height: 30px;
        }

    }
}
