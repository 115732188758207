/* droppable */
.action-twona-files-droppable {
    padding: 10px;
    text-align: center;
    &.in {
        line-height: 200px;
        color: $color-white;
        border-color: $color-white;
        @extend .bg-c4-light;

        &.hover {
            @extend .bg-c4-dark;
        }
    }
}
