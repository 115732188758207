.workflow-settings-edit {

    .hidden {
        display:none;
    }

    svg {
      background-color: transparent;
      &.dragging,
      &:active {
          cursor: crosshair;
      }
    }

    /* tool bar */
    g#buttons .button circle {
        stroke: #333;
        fill: #ccc;
        stroke-width: 1;
    }

    g#buttons .button line{
        stroke: black;
        fill: black;
        stroke-width: 1;
    }

    g#buttons .button  circle:hover {
        /*stroke: #333;
        fill: #ccc;*/
        stroke-width: 2;
        cursor: pointer;
    }

    g#buttons .new-state-text{
        border: 1px dotted #ccc;
        background: white;
        font: 12pt sans-serif;
        padding: 5px 10px;
        margin: 10px 0px 10px 10px;
    }

    g#buttons .button rect {
        stroke: #333;
        fill: #63B7AD;
        stroke-width: 1;
        cursor: pointer;
    }

    g#buttons .button image {
        stroke: #333;
        opacity: 0.5;
        fill: #63B7AD;
        stroke-width: 1;
        cursor: pointer;
        margin: 0px 0px 10px 10px;
    }

    g#buttons .button image:hover {
        opacity: 1;
    }

    g#buttons .button {

        &.save {
            display: none;

            fill: #F25A5A;
            text {
                color: #000;
                fill: #000;
                font: 12pt sans-serif;
            }

            &.active {
                display: inherit;

                rect {
    	           /* ... */
                   //animation: intermitent 4s infinite;
               }
           }
       }
   }

    /* remove area */

    g.remove {
        display: none;
        &.active {
            display: inherit;
        }

        rect {
            fill: #ffb9b9;
        }
    }

    /* status */
    g.status {
        circle.initial {
            display: none;
            r: 8;

            cursor: pointer;
            stroke: #888;
            stroke-width: 1px;
            stroke-dasharray: 2,2,2;
            fill: rgba(166,166,166, 0.3);

            &.active {
                stroke-width: 0px;
                display:inherit;
                fill: #C1BC1E;
            }
        }

        // active status
        &.active {
            circle.initial {
                display: inherit;
                &.active {
                    stroke-width: 1px;
                    display:inherit;
                }
                &:hover {
                    fill: rgba(166,166,166, 0.7);
                    stroke: #000;
                }
            }
        }

        // editable

        .editable{
            width: 80px;
            padding: 3px;
            height: 32px;
            text-align: center;
            border: 1px dotted gray;
            background-color: white;
            font: 10pt sans-serif;
            //overflow: hidden;
            //overflow-x: scroll;
        }
        .editable-container{
            width: 80px;
            height: 32px;
        }
    }

    /*g.status.active circle.initial-state-off {
        r: 8;
        fill: transparent;
        cursor: pointer;
        stroke: #888;
        stroke-width: 2px;
        stroke-dasharray: 2,2,2;
    }

    g.status circle.initial-state-on {
        r: 8;
        fill: #C1BC1E;
    }*/

    g.status text.label {
      font: 11pt sans-serif;
      pointer-events: inherit;
      cursor: text;
      /*z-index: 12000;*/
    }

    g.status circle.external {
        stroke: #ccc;
        fill: transparent;
        stroke-width: 1px;
        stroke-dasharray: 5,5,5;
        r: 60;
    }

    g.status.active circle.external {
       r:72;
       /*fill-opacity: 0.4;*/
    }

    g.status circle.external:hover {
       stroke: black;
    }

    g.status circle.main {
       stroke: black;
       /*fill: white;*/
       stroke-width: 0;
       r:50;
    }

    g.status.dragging circle.external {
       fill: rgba(166,166,166, 0.6);
       stroke-width: 1px;
    }

    g.status.active.dragging circle.main {
        cursor: move;
    }

    g.status circle.main:hover {
       stroke-width: 1px;
       /*cursor: pointer;*/
    }

    g.status .colors {
        display: hidden;
    }

    g.status.active .colors {
        display: inherit;
    }

    g.status.active .colors circle {
        r: 7;
        cursor: pointer;
    }

    g.status .colors circle:hover {
        r: 8;
    }



    g.transition {

    }

    g.transition circle.remove{
        stroke: #7EA2D5;
        fill: white;
        stroke-width: 1;
        r:10;
        cursor: pointer;
    }

    g.transition path.line{
        fill: none;
        stroke: #7EA2D5;
        stroke-width: 2px;
        /*cursor: default;*/
        marker-end: url(#arrow-marker);
    }

    g.transition.onactive path.line{
        fill: none;
        stroke: #7EA2D5;
        //stroke-width: 2px;
        /*cursor: default;*/
        marker-end: url(#arrow-marker-on-active);
    }

    g.transition.active path.line{
        fill: none;
        stroke: red;
        //stroke-width: 2px;
        stroke-dasharray: 10,2;
        marker-end: url(#arrow-marker-drag);
    }

    g.transition.active{
        cursor: crosshair;
    }

    g.transition line{
        stroke: #7EA2D5;
        fill: #7EA2D5;
        stroke-width: 1;
        /*cursor: pointer;*/
    }

    marker#arrow-marker-drag path{
        fill: red;
    }

    marker#arrow-marker path, marker#arrow-marker-on-active path{
        fill: #7EA2D5;
    }
}
