/* GENERAL BACKGROUND COLOR SETTINGS */

.global-light {background-color:  #F1F1F2;}
.global-dark {background-color:  #E6E7DE;}

.bg-c1-dark {background-color: #6D5C73;}
.bg-c1-light {background-color: #7F6F84;}

.bg-c2-dark {background-color: #CF6295;}
.bg-c2-light {background-color: #ED7A9B;}

.bg-c3-dark {background-color: #E85637;}
.bg-c3-light {background-color: #EC8568;}

.bg-c4-dark {background-color: #63B7AD;}
.bg-c4-light {background-color: #97CBC0;}

.bg-c5-dark {background-color: #39727F;}
.bg-c5-light {background-color: #4DA1AD;}

.bg-c6-dark {background-color: #D4212E;}
.bg-c6-light {background-color: #E84B4F;}

.bg-c7-dark {background-color: #E1AB32;}
.bg-c7-light {background-color: #EAC739;}

.bg-c8-dark {background-color: #CD612E;}
.bg-c8-light {background-color: #F7931D;}

.bg-c9-dark {background-color: #313B49;}
.bg-c9-light {background-color: #455569;}

.bg-c10-dark {background-color: #4D4E50;}
.bg-c10-light {background-color: #6D6F70;}

.bg-c11-dark {background-color: #949A9A;}
.bg-c11-light {background-color: #BEC0C6;}

.bg-c12-dark {background-color: #F1F1F2;}
.bg-c12-light {background-color: #F4F5F7;}

.bg-c13-dark {background-color: #6699cc;}
.bg-c13-light {background-color: #dee8f2;}
