.twona-messages {
    /*.block-header {
        //background-color: #6699cc;
        @extend .bg-c5-light;
    }*/

    .message-add-container{
        padding: 10px;
        display: block;

        .twona-helpers-form-wysiwyg {
            min-height: 180px;
            .js-editor-iframe {
                //js-editor-iframe has a default height: 300px in yellow-text.min.js
                height: 150px !important;
            }
        }
    }
}

.twona-message {
    /*&:nth-child(odd) {
        background-color: rgba(160, 160, 160, 0.1);
    }*/
    &.system {
        @extend .dimmed;
        @extend .bg-c7-light;
        .zebra:nth-child(odd) &{
            @extend .bg-c7-dark;
        }
    }

    .block-header {
        p {
            color: #000;
        }
    }

    .block-content {
        color: #000;
        /*> p:first-of-type {
            display: inline-block;
        }*/
        p {
            margin: 0;
        }
    }


}
