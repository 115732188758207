@import "view-panels";
@import "view-table";

.status-workflow {
    float: left;

    .header {
        img.deactive {opacity: 0.4}
    }

    .status-workflow-content-buttons {
        text-align: right;
        width: 200px;
        float: right;

    }
    .status-workflow-content {

    }
}


.twona-active-filters-notification {
    color: #fff;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0 .7em;
    //width: 100%;
    min-height: 50px;
    line-height: 50px;
    z-index: 2000;
    background: #53C0F6;
    font-size: 20px;
    vertical-align:middle;
    text-align:center;

    p {
        font-size: 28px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
