.input-select {
    background-color: #fff;
    color: #000;
    border:0px;
    padding:2px;
    padding-left: 10px;
    padding-right: 10px;
    font-family: Lato;
    font-weight: 300;
    font-size:16px;
    height:40px;
    &[multiple] {
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        padding: 0px;
        height: 100px;
        min-width: 200px;
    }
}

.input-select-small {
    background-color: #fff;
    color: #000;
    border:0px;
    padding:2px;
    font-family: Lato;
    font-weight: 300;
    font-size:12px;
    height:20px;
}
