/* CSS Document */

*:focus {outline: none;}

/* CLASSES FOR ACTIONS */

.action-input-toggle-label{} /* This class is used to display a value string when onclick the text field*/
.action-version-state-change{} /* This is used to change the state of a version*/
.action-user-active-change{}

/* -- SORTABLE ACTION --- */
.action-sortable-placeholder {}
.action-sortable-handler {
	min-width: 15px;
	cursor: ns-resize;
}

/* GENERIC CLASSES ---------------- */

body {
margin: 0;
padding: 0 0 12px 0;
background: #E6E7DE;
color: #F1F1F2;
font-size: 14px;
font-family: $font-family-default;
font-weight: 300;
overflow-x: hidden;
}
h1, h3 { color: #404041; font-size: 30px; font-weight:100 }
h2 { color: #404041; font-size: 12px; }
a:link { text-decoration: none; color: #404041; }
a:visited { text-decoration: none; color: #404041; }
a:active { text-decoration: none; color: #404041; }
a:hover { text-decoration: underline; color: #404041; }

p {color: #000000;}

.clearfix:after { content: "."; display: block; clear: both; visibility: hidden; line-height: 0; height: 0; }
.clearfix { display: inline-block; }
html[xmlns] .clearfix { display: block; }
* html .clearfix { height: 1%; }



/* GENERAL - TEXT */

.promo {font-size: 18px; font-weight: 100; line-height: 35px}


/* GENERAL - BUTTONS */

.button,a.button {cursor: pointer; color: #fff;text-align: center;font-weight: 300; vertical-align: middle}

.button.large {padding: 10px; height: 45px;font-size: 30px; line-height: 45px;}
.button.small {padding: 5px; height: 30px;font-size: 20px; line-height: 30px;}

.button.largeSquared {padding: 10px; height: 45px; width: 45px;font-size: 30px;}
.button.smallSquared {padding: 5px; height: 30px; width: 30px;font-size: 20px;}

.button.blue {background-color: #4DA1AD;}
.button.orange {background-color: #F7931D;}
.button.white {background-color: #FFFFFF;}

.button.dimmed {opacity: 0.5}

 .twona-version-item.even {
.v-number  {font-size:16px !important}
}

.archive-wrapper-right .twona-version-item.even {
max-width: 558px !important ;
}
.archive-wrapper-right .twona-version-item.even {
.v-number  {font-size:16px !important}
.v-identifier { background: #E9E8E7 !important}
.v-date-owner{ background: #E9E8E7 !important}
}

.twona-version-item.intable-artwork {
	.v-status span {color:#fff !important}
	.v-number  {font-size:16px !important;color:#fff !important}
	.v-number  span {color:#fff !important}
}

.archive-wrapper-right .twona-version-item,
.twona-version-item {
		height: 40px;
		display: flex !important;
		justify-content: space-between;
		width: 100%;
		max-width: 557px ;
		div {
				line-height: 40px;
		}
		.v-number {
				padding-left: 10px;
				padding-right: 10px;
		}

		.v-status {
				padding-left: 10px;
				padding-right: 10px;
				text-transform: uppercase;
				width: 100%;
				max-width: 200px;
				text-overflow: ellipsis;
				white-space: nowrap;
				//position: relative;
				span {
						display: block;
						-webkit-transition: 0.5s ease-in;
						-moz-transition: 0.5s ease-in;
						-o-transition: 0.5s ease-in;
						transition: 0.5s ease-in;
				}

				span:hover{
						-webkit-transition: 4s ease-in;
						-moz-transition: 4s ease-in;
						-o-transition: 4s ease-in;
						transition: 4s ease-in;
						text-indent: -200px;
				}
		}

		.v-date-owner {
				text-overflow: ellipsis;
				white-space: nowrap;
				padding-left: 10px;
				padding-right: 10px;

				width: 100%;

		}
		.v-identifier {
				text-overflow: ellipsis;
				white-space: nowrap;
				padding-left: 10px;

				padding-right: 10px;
				max-width: 80px;
		}
}

.status-space {
		width: 200px;
		height: 40px;
		overflow: hidden;
		text-align: right;
}

.owner {
		//width: 170px;
		height: 40px;
		text-align: left;
		overflow: hidden;
}
.blueButton,a.blueButton { cursor: pointer; color: #fff;text-align: center;font-weight: 100; font-size: 30px;  margin: 5px; padding: 10px; background-color: #4DA1AD; height: 45px;}
.orangeButton,a.orangeButton {cursor: pointer; color: #fff;text-align: center;font-weight: 100; font-size: 30px; margin: 5px; padding: 10px; background-color: #F7931D; height: 45px;}

.blueButtonSmall,a.blueButtonSmall {cursor: pointer; color: #fff;text-align: center;font-weight: 100;font-size: 30px;  margin: 5px; padding: 5px; background-color: #4DA1AD; height: 25px;}
.orangeButtonSmall,a.orangeButtonSmall  {cursor: pointer; color: #fff;text-align: center;font-weight: 100;font-size: 30px; margin: 5px; padding: 5px; background-color: #F7931D; height: 25px;}

.button.draggable {background: url(../img/asset_icon_draggable.png) repeat; cursor: ns-resize; z-index: 1000}

/* -- JUSTIFICATION -- */
.just-centered {text-align: center}
.just-left {text-align: left}
.just-right {text-align: right}

/* -- GENERAL - INPUT STYLES --- */
.border-black {border-color:#000;}
.input-text-dashed {color: #000; border:2px dashed; padding:5px;font-family: Lato; font-weight: 100;font-size:24px; height:45px; font-style: italic}
.input-text {color: #000; border:0px; padding:2px; padding-left: 10px; padding-right: 10px;font-family: Lato; font-weight: 100;font-size:16px; height:35px; width:100%; font-style: italic}
.input-label-text {color: #000; padding:2px;font-family: Lato; font-weight: 100;font-size:16px; height:35px;}


/* -- GENERAL - MARGIN --- */

.margin-small {margin: 5px;}
.margin-regular {margin: 10px;}
.margin-large {margin: 15px;}

.margin-left-small {margin-left: 5px;}
.margin-left-regular {margin-left: 10px;}
.margin-left-large {margin-left: 15px;}

.margin-right-small {margin-right: 5px;}
.margin-right-regular {margin-right: 10px;}
.margin-right-large {margin-right: 15px;}

.margin-top-small {margin-top: 5px;}
.margin-top-regular {margin-top: 10px;}
.margin-top-large {margin-top: 15px;}

.margin-bottom-small {margin-bottom: 5px;}
.margin-bottom-regular {margin-bottom: 10px;}
.margin-bottom-large {margin-bottom: 15px;}

/* -- DRAG&DROP --- */

.droppable {border: 2px dashed #455569; color: #313B49; padding: 5px; font-size: 16px; background: #fff}
.droppable.hover { border: 2px dashed #455569; color: #313B49; padding: 5px; font-size: 16px; background: #BEC0C6}
.draggable {cursor: move; z-index: 100000}
.draggable.dimmed {opacity: 0.5}

/* -- SETTINGS - USER MANAGEMENT --- */

.user-new-form {}
.user-new-form-container {padding: 10px; width: 300px;}

/* -- SETTINGS - WORKFLOW MANAGEMENT --- */

.workflow-new-form-container {padding: 10px; width: 300px; }
.workflow-item {padding: 10px; cursor: pointer; background-color: #E9E8E7;}
.workflow-item.odd{background-color: #E5E4E3; }
.workflow-item.active{background-color: #7CA3DC; }
.workflow-item.inactive { opacity: 0.3; }
.workflow-item:hover {background-color: #B9CAE8;}

/* -- SETTINGS - VERSION STATES --- */

.version-states-new-new-form-container {padding: 10px; width: 300px; }
.version-states-item {padding: 10px; cursor: pointer; background-color: #E9E8E7;}
.version-states-item.odd{background-color: #E5E4E3; }
.version-states-item.active{background-color: #7CA3DC; }
.version-states-item.inactive { opacity: 0.3; }
.version-states-item:hover {background-color: #B9CAE8;}
.version-states-groups-container{padding: 15px; max-width: 400px;}
.version-states-list-container {overflow-x:hidden; max-height: 220px; display: block;}
.version-states-button-area {float: right; width: 160px; height: 17px; margin-top: -10px; margin-right: -10px; padding: 10px;}
.version-states-button-area.odd {background: #E5E4E3;}
.version-states-button-area.even {background: #E9E8E7;}

/* -- VERSION DETAILS - VERSION STATES --- */

.version-details-version-states-item{ opacity: 0.4; padding: 5px; line-height: 30px; color: #000; font-size: 20px; font-weight: 100; color: #fff;}
.version-details-version-states-item.active{opacity: 1;}
.version-details-version-states-item:hover{opacity: 8;}
.version-details-button-area{min-height: 39px; cursor: auto;}
.version-details-button-area p{padding: 11px; margin: 0px;}
.version-details-button-area.odd {background-color: #f4f4f5; color: #000;}
.version-details-button-area.even {background-color: #e6e6e8; color: #000;}

/* -- COLLAPSIBLE SLECTABLE LIST --- */

.collapsible-list-item {padding: 10px; cursor: pointer; text-transform: uppercase; background-color: #E9E8E7; font-size: 18px; font-weight: 300; color: #fff;}
.collapsible-list-item.odd{background-color: #E5E4E3;}
.collapsible-list-item.even{background-color: #E9E8E7;}
.collapsible-list-item.active{background-color: #7CA3DC;}
.collapsible-list-item.inactive {opacity: 0.3;}
.collapsible-list-item.rollover:hover {opacity:0.6;}


/* -- NOTES -- */
.note-number { position: absolute; width: 25px; height: 25px; text-align: center; font-weight: bold; font-size: 18px; color: white; border-radius: 15px; border:3px solid #39727F; background: #4DA1AD}
.note-container { opacity: .95; position: absolute; padding: 5px; align: right; width: 150px; height: 100px; background-color: #EAC739;border: 3px solid #E1AB32; }
.note-extra { position: absolute; left: -15px; top: -15px; z-index: 103; width: 25px; height: 25px; text-align: center; font-weight: bold; font-size: 18px; color: white; border-radius: 15px; border:3px solid #39727F; background: #4DA1AD}
.note-buttons { position: absolute; float: right; top: 0px; left: 100%; margin-bottom: 4px; height: 12px; }
.note-buttons a { color: #fff;}
.note-textarea { width: 90%; height: 63%;background: transparent; margin-top: 15px; margin-bottom: 10px; border: 0px; color: #990000; font-size: 12px; font-weight: bold; resize: none; margin-bottom: 5px; }
.note-hide-button {position: absolute; left: -15px; top: -15px; z-index: 103; width: 25px; height: 25px; text-align: center; font-weight: bold; font-size: 18px; color: white; border-radius: 15px; border:3px solid #D4212E; background: #E84B4F}
.note-owner{position: absolute;bottom: 3px;left: 10px;font-size: 12px;}
//.note-hide-button {width: 25px; height: 25px; left: 0px; border: 1px solid white; text-align: center; font-weight: bold; font-size: 18px; color: white;}

/* STANDARD LIST ------------------ */
//.list .item { padding: 10px; cursor: pointer; background-color: #E9E8E7;  }
//.list .item.nocursor { cursor: none; }
//.list .item.nocursor:hover { cursor: auto; }
//.list .item.odd {background-color: #E5E4E3;}
//.list .item.active { background-color: #7CA3DC;}
//.list .item.deactive { opacity: 0.3;}
//.list .item  p { font-size: 14px; color: #000; line-height: 15px; margin:0px;}
//.list .item
//.list .item:hover, .list .item.odd:hover { cursor: pointer; background-color: #B9CAE8; }
//.list .marker { color: #fff; float: left; width: 5px; margin-right: 10px; font-size: 28px; font-weight: 700; text-align: center; line-height: 39px; display: inline-table; }
.list {
	.item {
		padding: 10px;
		cursor: pointer;
		/*background-color: #E9E8E7;*/
		&:nth-child(odd) {
			/*background-color: #E5E4E3;*/
			background-color: rgba(160, 160, 160, 0.1);
		}

		&:hover { cursor: pointer; background-color: #B9CAE8; }

		&.nocursor {
			cursor: none;
			&:hover {
				cursor: auto;
			}
		}

		&.active {background-color: #7CA3DC;}
		&.selected {background-color: #7CA3DC;}
		&.deactive, // old name
		&.inactive { opacity: 0.3;}

		p { font-size: 14px; color: #000; line-height: 15px; margin:0px;}
		.right { width: 278px; float: right; }
	}

	.marker { color: #fff; float: left; width: 5px; margin-right: 10px; font-size: 28px; font-weight: 700; text-align: center; line-height: 39px; display: inline-table; }
}
/* OLD SHIT ---------------- */
/* OLD SHIT ---------------- */
/* OLD SHIT ---------------- */
/* OLD SHIT ---------------- */
/* OLD SHIT ---------------- */


/* GENERAL CONTAINER ---------------- */

#generalContainer { position: relative; width: 1240px; border: 0; margin: 0 auto; }
#contentWindow { left: 0px; top: 0px; overflow: hidden; }

.detailContainer { color: #000; background-color: #F1F1F2; padding: 10px; margin-bottom: 10px; }
.detailContainer .nomargin{ margin-bottom: 0px; }
.detailContainer .right { float: right; width: 557px; background-color: #EDEDEC;}
.detailContainer .left {float: left; width: 557px; background-color: #EDEDEC;}
.detailContainer h1 { color: #000;  font-size: 40px; font-weight: 100; margin: 0px; }
.detailContainer a:hover { color: #000;  text-decoration: none; }
.detailContainer a:link { color: #000;  text-decoration: none; }
.detailContainer a:visited { color: #000;  text-decoration: none; }
.detailContainer a:active { color: #000;  text-decoration: none; }
.detailContainer h2 { color: #000;  font-size: 30px; font-weight: 100; margin: 0px; }
.content-overlay-forbidden { 	display: block;
					background: rgba(0, 0, 0, 0.6);
					position: fixed;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					z-index: 888888888;   
					color: #fff;
					.modal-forbidden {
						width: 100%;
						height: auto;
						background: #53C0F6;
						margin: 10% auto 0 auto;
						.modal-message {
							color: #fff;
							padding: 175px 0;
							background: #53C0F6;
							font-size: 24px;
							vertical-align: middle;
							text-align: center;
							line-height: 170%;
							p { color: #fff;}
							a {
								font-weight: bold;
								color: #fff;
								cursor: pointer !important;
								&:hover {
									text-decoration: underline;
								}
							} 
							.modal-button {
								font-weight: bold;
								width: 200px;
								margin: 40px auto 0 auto;
								cursor: pointer !important;
							}
						}
					}
}
/* TABS CONTAINER --------------------- */
#tab-panel-container .detailContainer .left,
#tab-panel-container .detailContainer .right {width: 540px;}
/* -- HOME STYLES --- */

#home { margin: 0 auto; width: 1055px; }
#home h1 { font-size: 22px; color: #b3b3b5; margin: 5px 0; text-transform: uppercase; font-weight: 300; }
#home div h3 { font-size: 22px; color: #fff; margin: 0; text-transform: uppercase; font-weight: 300; }
#home div h3 span.bignums { font-size: 50px; }
#home div p { font-size: 21px; color: #fff; margin: 0; }
#home .footer { font-size: 12px; color: #fff; margin: 0; padding: 5px; border-top: 1px solid rgba(255, 255, 255, 0.20); text-align: right; }
#home .footer a { color: #fff; text-decoration: none; }
#home .footer a:hover { color: #000; text-decoration: none; }
#home .content { overflow: hidden; padding: 0; }
#home .content p { font-size: 13px; color: #fff; margin: 15px 0; padding: 0; }
#home .content p.blog { margin: 0 !important; }
#home .content p.blog a { color: #fff; text-decoration: none; font-size: 12px; }
#home .content p.blog a:hover { color: #000; }
#home .content h4 { font-size: 13px; margin: 5px 0; padding: 0; }
#home .content h4 a { color: #fff; text-decoration: none; }
#home .content h4 a:hover { color: #fff; text-decoration: underline; }
#home .news h4 { color: #fff; margin: 15px 0 0; }
#home .smallmodule { height: 60px; margin: 0 0 15px 0; }
#home .bigmodule { height: 205px; margin: 0 0 25px 0; }
#homesubone { float: left; width: 455px; padding: 0 !important; }

#homeone { float: left; background: #000; width: 140px; height: 460px; padding: 15px; margin: 0 5px 0 0; padding: 0 !important; overflow: hidden; }
#hometwo { float: left; background: #33a142; width: 270px; height: 120px; padding: 15px; margin: 0 5px 5px 0; }
#homethree { float: left; background: #de8e01; width: 270px; height: 120px; padding: 15px; margin: 0 5px 5px 0; }
#homefive { float: left; background: #bcbcbe; width: 120px; height: 120px; padding: 15px; margin: 0 5px 5px 0; }
#homesix { float: right; background: #28769c; width: 265px; height: 275px; padding: 15px; margin: 0 5px 5px 0; }
#homeseven { float: left; background: #8947b1; width: 120px; height: 120px; padding: 15px; }
#homeeight { float: left; background: #a90063; width: 120px; height: 120px; padding: 15px; }
#homenine { float: left; background: #649db1; width: 270px; height: 120px; padding: 15px; margin: 0 5px 5px 0; }
#hometen { float: left; background: #540352; width: 120px; height: 120px; padding: 15px; }

#homefour { background: #EAC739; width: 527px; height: 275px; padding: 15px; margin: 0 0 5px 0; }
/* deprecate */
/*#homefour #datepicker { height: 225px !important; }
#homefour #datepicker .ui-datepicker { background: transparent; width: 527px; padding: 0; }
#homefour #datepicker .ui-datepicker .ui-widget-header { background: transparent; padding: 0; font-weight: 100; font-family: 'Lato', Verdana, Arial, Helvetica, sans-serif; font-size: 32px; text-transform: uppercase; }
#homefour #datepicker .ui-datepicker .ui-datepicker-title { margin: 0; line-height: 27px; text-align: center; }
#homefour #datepicker .ui-datepicker .ui-datepicker-prev, #homefour #datepicker .ui-datepicker .ui-datepicker-next { height: 24px; cursor: pointer; }
#homefour #datepicker .ui-datepicker .ui-widget-header .ui-datepicker-prev .ui-icon { background: url(../img/sprite_arrows.png) -1px -1px no-repeat; }
#homefour #datepicker .ui-datepicker .ui-widget-header .ui-datepicker-next .ui-icon { background: url(../img/sprite_arrows.png) -15px 0 no-repeat; }
#homefour #datepicker .ui-datepicker .ui-state-hover { border: 0; margin: 0; background: transparent; }
#homefour #datepicker .ui-datepicker .ui-datepicker-prev-hover { left: 0; top: 3px; }
#homefour #datepicker .ui-datepicker .ui-datepicker-prev-hover .ui-icon { background: url(../img/sprite_arrows.png) 1px -20px no-repeat !important; }
#homefour #datepicker .ui-datepicker .ui-datepicker-next-hover { right: 2px; top: 4px; }
#homefour #datepicker .ui-datepicker .ui-datepicker-next-hover .ui-icon { background: url(../img/sprite_arrows.png) -15px -20px no-repeat !important; }
#homefour #datepicker .ui-datepicker a.ui-state-default { border: 0; background: transparent; font-size: 20px; font-family: 'Lato', Verdana, Arial, Helvetica, sans-serif; font-weight: 100; padding: 3px; text-align: center; }
#homefour #datepicker .ui-datepicker a.ui-state-default:hover { border: 0; background: #E1AB32; }
#homefour #datepicker .ui-datepicker a.ui-state-active { background: #E1AB32 !important; }
#homefour #datepicker .ui-datepicker th { font-weight: normal; font-family: 'Lato', Verdana, Arial, Helvetica, sans-serif; font-size: 16px; color: #fff; padding: 25px 1px 3px; }
#homefour #datepicker .ui-datepicker td { padding: 0; }*/
/* end deprecate */

.datepicker { min-height: 320px}
.datepicker .ui-datepicker { background: transparent; width: 527px; padding: 0;min-height: 320px  }
.datepicker .ui-datepicker .ui-widget-header { z-index:10000000;background: transparent; padding: 0; font-weight: 300; font-family: 'Lato', Verdana, Arial, Helvetica, sans-serif; font-size: 32px; text-transform: uppercase; display:flex ; justify-content:space-between ;padding-bottom: 12px }
.datepicker .ui-datepicker .ui-datepicker-title { margin: 0; line-height: 27px; text-align: center;position: relative ;padding-top:10px ;width:80% ;display: flex ;justify-content: center }
.datepicker .ui-datepicker .ui-datepicker-prev, #homefour #datepicker .ui-datepicker .ui-datepicker-next { height: 24px; cursor: pointer;}
.datepicker .ui-datepicker .ui-widget-header .ui-datepicker-prev .ui-icon { background: url(../img/sprite_arrows.png) -1px -1px no-repeat; }
.datepicker .ui-datepicker .ui-datepicker-calendar {margin-top: -15px;margin-bottom: -5px }
.datepicker .ui-datepicker .ui-datepicker-calendar  td {padding-bottom: 10px }
.datepicker .ui-datepicker .ui-datepicker-calendar  td span {font-size: 120%;}
.datepicker .ui-datepicker .ui-datepicker-calendar  th {padding-bottom: 8px !important;}
.datepicker .ui-datepicker .ui-datepicker-calendar  th span {font-size: 110%;font-weight: 600;}
.datepicker .ui-datepicker .ui-widget-header .ui-datepicker-next {margin-right: 12px ;margin-top:8px ;}
.datepicker .ui-datepicker .ui-widget-header .ui-datepicker-prev {margin-left: 15px ;margin-top: 8px;}
.datepicker .ui-datepicker .ui-widget-header .ui-datepicker-next,
.datepicker .ui-datepicker .ui-widget-header .ui-datepicker-prev {width:20px;height:20px;}
.datepicker .ui-datepicker .ui-widget-header .ui-datepicker-next .ui-icon { background: url(../img/sprite_arrows.png) -15px 0 no-repeat; }
.datepicker .ui-datepicker .ui-state-hover { border: 0; margin: 0; background: transparent; }
/*.datepicker .ui-datepicker td.ui-datepicker-today a { background-color: #6abdcd !important;}*/
.datepicker .ui-datepicker .ui-datepicker-prev-hover { left: 0; top: 3px; }
.datepicker .ui-datepicker .ui-datepicker-prev-hover .ui-icon { background: url(../img/sprite_arrows.png) 1px -20px no-repeat !important; }
.datepicker .ui-datepicker .ui-datepicker-next-hover { right: 2px; top: 4px; }
.datepicker .ui-datepicker .ui-datepicker-next-hover .ui-icon { background: url(../img/sprite_arrows.png) -15px -20px no-repeat !important; }
.datepicker .ui-datepicker a.ui-state-default { border: 0; background: transparent; font-size: 22px; font-family: 'Lato', Verdana, Arial, Helvetica, sans-serif; font-weight: 300; padding: 5px 0px; text-align: center; }
.datepicker .ui-datepicker a.ui-state-default:hover,
/*.datepicker .ui-datepicker a.ui-state-highlight:hover { border: 0; background-color: #E1AB32; }
.datepicker .ui-datepicker a.ui-state-highlight {background-color: #6abdcd !important; };*/
.datepicker .ui-datepicker a.ui-state-active,
.datepicker .ui-datepicker td.ui-datepicker-today a.ui-state-active { background-color: #E1AB32 !important; }
.datepicker .ui-datepicker th { font-weight: normal; font-family: 'Lato', Verdana, Arial, Helvetica, sans-serif; font-size: 16px; color: #fff; padding: 25px 1px 3px; }
.datepicker .ui-datepicker td { padding: 0; }
.datepicker .active-date {background: #488DA0 !important; }

.myHomeContainer {margin-top: 35px;}

/* MESSAGES ---------------- */

#newMessageFormArea {display: none; font-family: Lato; font-size: 14px; font-weight:100; color: #000; background-color:#C4EFEB; padding: 15px;}

#newMessageButton { position: relative; opacity: 0.5; background-color:#6D6F70; margin: 0px; padding: 5px; font-family: Lato;}
#newMessagesButton p {color: #fff; font-size: 14px; margin: 0px;text-align: left;}
.messageHeader {font-family:Lato; font-size: 24px; font-weight: 100; color: #fff; margin-left: 20px;}

.messagesContainer {position: relative;  margin-top: 0px; margin-left: 0px; font-size:14px;}
.messagesListContainer {position: relative; width: 390px; padding: 0px; border: 0px solid #999999; color: #fff;}
.messageInList {position: relative;height: 70px; background-color: #fff; color: #000; padding: 0px; margin-top: 0px;}
.messagesListContainer .par {background-color:#DEE0CD;}
.messagesListContainer .impar {background-color:#E6E7DE;}
.messagesListContainer .unread {background-color:#EAC739;}
.messageProfilePicture {float: left; display: block; margin-right: 5px; width: 70px; height: 70px;}

#messageContent {position: relative; min-height: 30px;float: right; width: 700px; margin-top: 0px; background-color:#EAC739; padding: 20px;color: #000; line-height: 1.2em; font-size:14px; margin-left: 20px; font-color: #404041; height:100%;}

/* TODOS ------------------------ */
#todoListArea, #todoListContainer { background-color: #7FCAAD; }
#todoListContainer .header { background-color: #7FCAAD; padding: 10px; font-family: Lato; font-size: 32px; font-weight: 100; color: #fff; text-align: center;}
#todoListContainer .header h1 { font-size: 32px; color: #fff; margin: 0px;}
#todoListContainer .add { padding: 5px;}
#todoListContainer form { display: inline; }
#todoListContainer form input { font-size: 14px; border: 2px dashed #39727F; padding: 5px; color: #72B1C8; font-weight: 100;}
#todoListContainer .add input { width: 500px; }
#todoListContainer #todoEditForm input { width: 400px; }
#todoListContainer .content { max-height: 201px; overflow-x: hidden; overflow-y: scroll;}
#todoListContainer .content ul { margin: 0px; padding: 0px;}
#todoListContainer .content ul li { margin: 0px; background-color: #97CBC0; list-style: none; line-height: 40px; font-size: 14px; font-weight: 100; color: #fff;}
#todoListContainer .content ul li span.todoListDoContainer { padding: 3px; margin-right: 20px; text-align: center; background-color: #63B7AD; height: 40px; width: 40px; display: inline-block; }
#todoListContainer .content ul li.even { background-color: #63B7AD;}
#todoListContainer .content ul li.even span.todoListDoContainer { background-color: #97CBC0; }
#todoListContainer .content ul li.inactive { background-color: #B9A9A9; text-decoration: line-through; }
#todoListContainer .content ul li.inactive span.todoListDoContainer { background-color: #C7BABA; }
#todoListContainer .content ul li.inactive.even { background-color: #CCC0C1;}
#todoListContainer .content ul li.inactive.even span.todoListDoContainer { background-color: #D6CDCD; }

/* INNER CONTENT ---------------- */

#SCR1, #SCR2, #SCR3 { display: block; padding: 40px; position: absolute; top: 110px; width: 1160px; height: 960px;  z-index: 1000; }
#SCR1 { opacity: 1; /* left: 0px; */}
#SCR2 { opacity: 0.5;  /* left: 1240px;  */}
#SCR3 { opacity: 0.25; /*  left: 2480px; */}

#leftContent { float: left; width: 197px; }
#centerContent { position: relative; float: left; margin-left: 30px; text-align: left; }
#loadingCentralContent { display: none; margin: 10px 0 0 10px; text-align: center; width: 700px; height: 400px; }
#centralContentContainer { text-align: center; width: 900px; margin: 0px; border: 0; }

/* STATUS CONTENT ---------------- */

#requests { padding-bottom: 50px; float: left; }
.status-wrapper { float: left; width: 1130px; background: #F1F1F2; padding: 15px; }
.status-wrapper-left { float: left; width: 557px; background-color: #E6E7DE; }
.status-wrapper-right { float: right; width: 557px; background-color: #E6E7DE;}

.status-wrapper h1 {font-size: 40px; color: #000; font-weight: 100}
.status-wrapper h2 {font-size: 20px; color: #000; font-weight: 100}
.status-wrapper h3 {font-size: 14px; color: #000; font-weight: 300}

.status-search { display: none; float: left; width: 1130px; background: #f9f9fa; padding: 15px 15px 15px; }
.status-search fieldset { border: 0; margin: 12px 0 10px; padding: 0; }
.status-search fieldset > div { float: left; margin-right: 30px; }
.status-search fieldset div:first-child { width: 450px; margin-right: 60px; }
.status-search fieldset div label { float: right; font-size: 14px; color: #333; line-height: 27px; }
.status-search fieldset div input[type='text'] { width: 250px; height: 30px; padding: 0 5px; background: #e0e0e1; border: 0; font-size: 13px; color: #999999; }
.status-search fieldset div input[type='submit'] { width: 85px; height: 30px; padding: 0 5px; margin-top: 30px; background: #e0e0e1; border: 0; font-size: 14px; font-weight: bold; text-transform: uppercase; color: #999999; cursor: pointer; }
.status-search fieldset div input[type='submit']:hover { color: #000; }
.status-search fieldset div ul { padding: 0; margin: 0; list-style-type: none; }
.status-search fieldset div ul li { margin: 4px 0; }
.status-search fieldset div ul li a { font-size: 14px; color: #777; background: url(../img/search_selected.png) 0 2px no-repeat; padding-left: 25px; }
.status-search fieldset div ul li a:hover { color: #000; text-decoration: none; }
.status-search fieldset div ul li a.selected { background-position: 0 -18px; }
.status-search fieldset div:last-child { float: right; color: #000; font-size: 13px; font-weight: bold; margin-right: 0; }
.status-search fieldset div:last-child a { color: #000; }
.status-search fieldset div:last-child a:hover { color: #ccc; text-decoration: none; }

.status-ui-state-default { border: 0; padding: 0; cursor: pointer; }
.status-ui-state-default:hover { opacity: 0.8; }
.status-ui-state-default.notclickable { cursor: auto; }
.status-ui-state-default.notclickable:hover { opacity: 1; }
.status-ui-state-default .status-count { color: #fff; float: left; width: 45px; margin-right: 10px; font-size: 28px; font-weight: 700; background: rgba(255, 255, 255, 0.40); text-align: center; line-height: 39px; display: inline-table; }
.status-ui-state-default .status-count-empty { color: #fff; float: left; width: 5px; margin-right: 10px; font-size: 28px; font-weight: 700; background: rgba(255, 255, 255, 0.40); text-align: center; line-height: 39px; display: inline-table; }
.status-ui-state-default p { color: #fff; margin: 0; font-size: 14px; padding: 11px; text-transform: uppercase; }
.status-ui-state-default.nolink { cursor: auto; }
.status-ui-state-default.nolink:hover { opacity: 1; }
.status-ui-widget-float { float: left; width: 100%; }
.status-ui-widget-content { background: #fff; padding: 0; display: block; border: 0; font-size: 14px; font-weight: 300;}
.status-ui-widget-content table { width: 100%; border-spacing: 0; color: #000; }
.status-ui-widget-content tr.impar { background-color: #e5e5e5; }
.status-ui-widget-content tr.par { background-color: #d9d9d9; }
.status-ui-widget-content tr.imparatach { background-color: #c7c7c7; }
.status-ui-widget-content tr.paratach { background-color: #d1d1d1; }
.status-ui-widget-content table td { vertical-align: top; }
.status-ui-widget-content table td.status-info {max-width:300px !important;}
.status-ui-widget-content table td.status-info a {display: inline-block;max-width:280px !important;line-height: 130%; word-break: break-all;}
.status-ui-widget-content table td.status-info { padding: 15px; width: 100%; }
.status-ui-widget-content table td.status-info .right { float: right; }
.status-ui-widget-content table td.status-version,
.status-ui-widget-content table td.status-file,
.status-ui-widget-content table td.status-reqinfo { padding: 0; width: 44px; }
.status-ui-widget-content table td.status-version .status-count { color: #fff; padding: 0 10px; font-size: 18px; font-weight: 700; background: rgba(160, 160, 160, 0.2); text-align: center; line-height: 39px; }
.status-ui-widget-content table td.status-version .status-count.status-dialog { padding: 0 !important; height: 39px; }
.status-ui-widget-content table td.status-version .version-lower { font-weight: bold; font-size: 31px; color: #fff; text-align: center; line-height: 39px; }
.status-ui-widget-content table td.status-version .version-lower-icons { position: relative; top: -39px; }
.status-ui-widget-content table td.status-version .version-lower-icons-in { position: absolute; right: 44px; width: 176px; height: 39px; }
.status-ui-widget-content table td.status-version .version-lower-icons-in > div { float: right; height: 39px; }
.status-ui-widget-content table td.status-version .version-lower-icons-in > div a { margin: 0; padding: 0; display: block; height: 39px; }
.status-ui-widget-content table td.status-file .status-filetype { color: #fff; padding: 0; font-size: 12px; background: rgba(255, 255, 255, 0.20); text-align: center; line-height: 47px; font-weight: 700; width: 44px; }
.status-ui-widget-content table td.status-reqinfo .status-reqinfonum {
	color: #fff;
	padding: 0;
	font-size: 12px;
	background-color: rgba(255, 255, 255, 0.20);
	background-image: url(../img/asset_info.png);
	background-position: 50% 22px;
	background-repeat: no-repeat;
	text-align: center;
	line-height: 25px;
	font-weight: 700;
	width: 44px;
	height: 47px
}
.status-ui-widget-content table td.status-priority { padding: 0; width: 44px; display: inline-block; }
.status-ui-widget-content table td.status-priority .priority-up { padding: 0; background: rgba(255, 255, 255, 0.50); }
.status-ui-widget-content table td.status-priority .priority-up:hover { padding: 0; background: rgba(255, 255, 255, 0.40); }
.status-ui-widget-content table td.status-priority .priority-up .priority-arrow { cursor: pointer; width: 44px; height: 39px; background: url('../img/sprite_updown.png') 0 0 no-repeat; }
.status-ui-widget-content table td.status-priority .priority-down { padding: 0; background: rgba(255, 255, 255, 0.70); }
.status-ui-widget-content table td.status-priority .priority-down:hover { padding: 0; background: rgba(255, 255, 255, 0.60); }
.status-ui-widget-content table td.status-priority .priority-down .priority-arrow { cursor: pointer; width: 44px; height: 39px; background: url('../img/sprite_updown.png') 0 -39px no-repeat; }
.status-ui-widget-content table td.status-comments { padding: 0; }
.status-ui-widget-content table td.status-comments div { background: url('../img/asset_comments.png') top right no-repeat; margin: 18px 8px 0 20px; padding: 2px 8px 6px 0; display: inline-block; }
.status-ui-widget-content table td.status-archives div { background: url('../img/asset_archives.png') top right no-repeat; margin: 18px 25px 0 0; padding: 2px 8px 6px 0; display: inline-block; }
.status-ui-widget-content table td.status-comments div span, .status-ui-widget-content table td.status-archives div span { background: #c3c3c3; padding: 2px 0 2px 5px; color: #fff; font-weight: 700; }
.status-ui-widget-content .myDragClass td { background-color: #669900; }
.status-ui-widget-content table a { cursor: pointer; color: #000; }
.status-ui-widget-content table a:hover { cursor: pointer; text-decoration: none; background: #555; color: #fff; }
.status-ui-widget-content table td p { margin: 4px 0; }
.status-ui-widget-content table td p span.requestRef { color: #000; font-weight: bold; font-size: 14px; }
.status-ui-widget-content table td p span.requestInfo { font-size: 14px; color: #333; }
.status-ui-widget-content table .showauthor { width: 200px; }
.status-ui-widget-content table .showauthor a { text-decoration: underline; color: #000; padding: 0 10px; }
.status-ui-widget-content table .showauthor a:hover { text-decoration: none; background: none; }
.status-ui-widget-content table td .square { width: 20px; height: 20px; }
.status-ui-widget-content tr.pagination { background: #ccc; }
.status-ui-widget-content tr.pagination td div.pagination-list { padding: 10px 12px; font-weight: 700; }
.status-ui-widget-content tr.pagination td div.pagination-list span { padding: 0 3px; color: #666666; }
.status-ui-widget-content tr.pagination td div.pagination-list a { padding: 0 3px; color: #fff; }
.status-ui-widget-content tr.pagination td div.pagination-list a:hover { background: #ddd; color: #000; }


.status-containment { display: inline-block; min-height:39px;}
.status-containment .status-ui-state-default:hover {cursor:move;}
.status-wrapper-right .status-ui-widget { float: left; display: inline-block; width: 100%; }
.status-wrapper-right .ui-widget-content { float: left; display: inline-block; width: 100%; }
.status-wrapper-right .comments .comment,
.archive-wrapper .comments .comment { float: left; }
.status-wrapper-right .comments .com_avatar,
.archive-wrapper .comments .com_avatar { float: left; height: 78px; width: 77px; }
.status-wrapper-right .comments .commentin,
.archive-wrapper .comments .commentin { float: left; padding: 15px; font-size: 14px; width: 450px; color: #000000}
.status-wrapper-right .comments .commentin p,
.archive-wrapper .comments .commentin p { margin: 0; }
.status-wrapper-right .comments .commentin p:first-child,
.archive-wrapper .comments .commentin p:first-child { margin-bottom: 10px; }
.status-wrapper-right .comments .leavecom { margin-left: 10px; padding: 0px; float: left; width: 528px; margin: 0; background: #fff; }
.status-wrapper-right .leavecom { padding: 0px; background: #fff; font-family: 'Lato', Verdana, Arial, Helvetica, sans-serif; font-size: 14px; color: #3e3c3c; }
.status-wrapper-right .leavecom strong { font-weight: normal; }
.status-wrapper-right .leavecom a.ui-button { background: #88C2EA; margin-top: 15px !important; }
.status-wrapper-right .leavecom a.ui-button span { color: #333; font-family: 'Lato', Verdana, Arial, Helvetica, sans-serif; font-size: 14px; font-weight: bold; text-transform: uppercase; }

.leavecom a.ui-button { background: #88C2EA; margin-top: 15px !important; }
.leavecom a.ui-button span { color: #333; font-family: 'Lato', Verdana, Arial, Helvetica, sans-serif; font-size: 14px; font-weight: bold; text-transform: uppercase; }

/* NEW REQUEST */
/* By Isaac */
.status-ui-newrequest-content { padding: 10px; display: block; border: 0; font-size: 14px; font-weight: 300;}
.status-ui-newrequest-content table { width: 100%; border-spacing: 0; color: #000; }
.status-ui-newrequest-comments {}
.status-ui-newrequest-button { color: #fff; float: right; width: 45px; margin-right: 0px; font-size: 12px; font-weight: 700; background: rgba(255, 255, 255, 0.40); text-align: center; line-height: 0px; display: inline-table; }
.status-ui-newrequest-button-createdraft { background: url('../img/asset_button_createrequestdraft.png') top right no-repeat; margin: 0px 0px 0 0px; padding: 0px 0px 0px 0; display: inline-block; width: 45px; height: 39px; text-indent: -1000px; overflow: hidden;}
/* By Wass */
#new-request .newrequest-button { cursor: pointer; width: 450px; margin: 0 auto; }
#new-request .newrequest-button > div { margin: 5px 0; }
#new-request .newrequest-button p { margin: 0; }
#new-request .newrequest-button .newrequest-button-iconaccept { background: url('../img/asset_accept_drafts.png') no-repeat; width: 92px; height: 80px; float: left; }
#new-request .newrequest-button .newrequest-button-iconcancel { background: url('../img/asset_cancel_drafts.png') no-repeat; width: 92px; height: 80px; float: left; }
#new-request .newrequest-button .newrequest-button-text { float: right; line-height: 80px; width: 358px;height: 80px; font-size: 30px; font-weight: 100; text-align: center; }
/* By Rafa */
#transitions-list .transition-list-item,
#archive-results-versions-states .archive-results-versions-states-item,
#newrequest-workflow .newrequest-workflow-item { padding: 5px; /*background-color: #EDECEC;*/  line-height: 30px; color: #000; font-size: 20px; font-weight: 100; }
//#transitions-list .transition-list-item.odd,
//#archive-results-versions-states .archive-results-versions-states-item.odd,
//#newrequest-workflow .newrequest-workflow-item.odd { background-color: #F3F2F2; }
//#transitions-list .transition-list-item.active,
//#archive-results-versions-states .archive-results-versions-states-item.active,
//#newrequest-workflow .newrequest-workflow-item.active { background-color: #7CA3DC; color: #fff; }
//#transitions-list .transition-list-item:hover,
//#archive-results-versions-states .archive-results-versions-states-item:hover,
//#archive-results-versions-states .archive-results-versions-states-item.odd:hover,
//#transitions-list .transition-list-item.odd:hover,
//#newrequest-workflow .newrequest-workflow-item:hover,
//#newrequest-workflow .newrequest-workflow-item.odd:hover { background-color: #B9CAE8; }
//.newrequest-workflow-item.inactive { opacity: 0.3; }

/* STATUS TABS */
.etabs { margin: 0; padding: 0; }
.tab { opacity: 0.3;display: inline-block; zoom:1; *display:inline; background: #F7F7F9 url('../img/asset_tab.png') top right no-repeat; max-width: 130px; width: 130px; }
.tab a { color: #6d6d6d; font-size: 14px; text-transform: uppercase; font-weight: bold; display: block; padding: 12px 20px 12px 10px; outline: none; }
.tab.closebtn a.label { display: inherit; width: 80px; padding: 12px 10px; font-weight: normal; }
/*.tab.closebtn .close { display: inherit; padding: 0; }*/
.tab.closebtn .close { background: #fff; padding: 0 3px; font-family: Arial Black; font-size: 13px; }
.tab.closebtn .close:hover { color: #000; }
.tab a:hover { color: #000; text-decoration: none; }
.tab.active { opacity: 1;background: #F1F1F2 url('../img/asset_tab_on.png') top right no-repeat;; }
.tab a.active { font-weight: bold; text-transform: uppercase; color: #aaa; text-decoration: none; }
.etabs #tab-requests #requeststab { display: inline-block; padding: 12px 13px 12px 10px !important; }
.etabs #tab-requests #requeststab:hover { color: #000; }
.etabs #tab-requests #searchtab { display: inline-block; background: url('../img/search.png') 0 0 no-repeat; padding: 8px; }
.etabs #tab-requests #searchtab:hover { background-position: 0 -17px; }
.etabs #tab-new-request { float: right; background: #F7F7F9 url('../img/asset_tabnew.png') top left no-repeat; }
.etabs #tab-new-request.active { float: right; background: #F1F1F2 url('../img/asset_tabnew_on.png') top left no-repeat; }
.etabs #tab-new-request a { padding: 12px 10px 12px 20px; }

.etabs #tab-my-filters { float: right; background: #ededef url('../img/asset_tabnew.png') top left no-repeat; }
.etabs #tab-my-filters.active { float: right; background: #f9f9fa url('../img/asset_tabnew_on.png') top left no-repeat; }
.etabs #tab-my-filters a { padding: 12px 10px 12px 20px; }

/* BOX style (test) --------------- */
.box { margin-bottom: 10px; background-color: #E9E8E7; }
.box .status-wrapper-right, .box .status-wrapper-left { background-color: #EDEDEC;}
.box h1 { color: #000;  font-size: 40px; font-weight: 100; margin: 0px; }
.box h2 { color: #000;  font-size: 30px; font-weight: 100; margin: 0px; }
.box .header { padding: 10px;}

.status-loading-content{
	height: 100%;width: 1160px;position: absolute;background: white;opacity: 0.8;z-index: 1001;
	.loading-img{width: 100%;margin-left:auto;margin-right: auto;text-align: center;margin-top: 50px;}
}
/* SETTINGS ----------------------- */
#settingsContainer { }
#settingsContainer .newUserFormContainer { padding: 10px;}
/*
#settingsContainer .newUserFormContainer input { width: 90%; border: 2px dashed #000; font-size: 20px; padding: 5px; font-family: Lato;font-weight: 100;font-size: 24px;}
#settingsContainer .newUserFormContainer .newUserButton { background-color: #45A2CC; cursor: pointer; text-align: center; color: #fff; font-size: 26px; padding: 10px;}
*/

#usersListContainer .header { padding: 10px;}
#usersListContainer .usersListContent {max-height: 201px; overflow-x: hidden;}

#usersListContainer .usersListContent .item { padding: 10px; cursor: pointer; background-color: #E9E8E7;  }
#usersListContainer .usersListContent .item.odd {background-color: #E5E4E3;}
#usersListContainer .usersListContent .item.active { background-color: #7CA3DC;}
#usersListContainer .usersListContent .item.deactive { opacity: 0.3;}
#usersListContainer .usersListContent .item  p { font-size: 14px; color: #000; line-height: 15px; margin:0px;}
#usersListContainer .usersListContent .item .right { width: 278px; float: right; }
#usersListContainer .usersListContent .item:hover, #usersListContainer .usersListContent .item.odd:hover { cursor: pointer; background-color: #B9CAE8; }

/* PROFILE ------------------------ */
#profileContainer { }
/*#profileContainer header { padding: 10px; } */
#profileContainer h1 { color: #000;  font-size: 40px; font-weight: 100; margin: 0px; }
#profileContainer h2 { color: #000;  font-size: 30px; font-weight: 100; font-style: italic; text-decoration: underline; }
/*#profileContainer header h1 { margin: 0px;}
#profileContainer header h1 a { color: black; }
#profileContainer header .left, #profileContainer header .right { width: 250px;} */
#profileContainer .header .right { text-align: right; }
#profileContainer .profileFormItem { display: none;}
#profileContainer .content h1 { margin-top: 15px;}
#profileContainer .content .left, #profileContainer .content .right { width: 523px; height: 500px; padding: 20px;}
#profileContainer .content .left .left, #profileContainer .content .left .right  { padding: 0px; width: 250px; }
#profileContainer .right, #profileContainer .content .left .right { float: right; }
#profileContainer .content p { font-size: 24px; font-weight: 100; }
#profileContainer .content p label { font-size: 12px; font-weight: 100; }
#profileContainer .content .changePasswordButton, #profileContainer .content .changePasswordSaveButton { cursor: pointer; text-align: center; color: #fff; font-size: 26px; padding: 10px;}
#profileContainer .content .changePasswordButton { background-color: #A5A2A0; }
#profileContainer .content .changePasswordSaveButton, #profileSaveButtonsArea .profileSaveButton {  }
#profileContainer .content input { width: 90%; border: 2px dashed #000; font-size: 20px; padding: 5px; font-family: Lato;font-weight: 100;font-size: 24px;}
#profileContainer .content textarea { width: 90%; border: 2px dashed #000; font-size: 20px; padding: 5px; font-family: Lato;font-weight: 100;font-size: 20px;}
#profileChangePasswordSaveArea { font-size: 16px; background-color: #D2CFCE; display: none;}
#profileSaveButtonsArea { text-align: center; margin-top: 100px; display: none;}
#profileSaveButtonsArea .profileSaveButton, #profileSaveButtonsArea .profileCancelButton { cursor: pointer; color: #fff; text-align: center; font-size: 30px; font-weight: 100; padding: 10px; margin: 5px; }
#profileSaveButtonsArea .profileCancelButton { background-color: #FF9C42;}
#profileContainer .content .profileAvatar img { border: 10px solid #fff;}
#profileChangeAvatarForm { visibility: hidden; }
#profileChangePasswordArea {margin-top: 0px; width: 223px;}
#profileCloseButton {color: #000;text-decoration: none}

#transition-groups-list .group-item,
#profileContainer .content #profileGroupsContainer .profileGroupItem { white-space:nowrap; line-height: 38px; font-size: 20px; weight: 100px; padding: 5px; margin-bottom: 10px; background-color: #4DA1AD; color: #fff;}
#profileContainer .content #profileGroupsContainer .profileGroupItem a.removeButton { margin-left:10px; font-size: 24px; color: #fff;}
#transition-groups-list .group-item.inactive { opacity: 0.5;}
#transition-groups-list .group-item { margin-right: 5px; margin-bottom: 10px;}
#transition-groups-list .group-item a {color: #fff; }

.groups-wrapper { width: 1130px; background: #E6E7DE; padding: 0px; overflow: hidden; }
.groups-tool { float: left; width: 214px; background: #2c92c6; padding: 0px; color: #fff; font-size: 14px; margin-left: 10px; margin-top:10px; margin-bottom: 10px; }
.groups-tool .noscroll { background: #fff !important; height: 175px !important; }
.groups-tool a { color: #fff;}
.groups-tool-item {height: 25px;}
.groups-tool-item-par {background-color: #4da3cf;min-height: 25px; padding-left: 10px; padding-top: 3px; padding-bottom: 3px;}
.groups-tool-item-par:hover {background-color: #FB7FB5; opacity: 0.6}
.groups-tool-item-impar {background-color: #6eb3d8;min-height: 25px; padding-left: 10px;padding-top: 3px; padding-bottom: 3px;}
.groups-tool-item-impar:hover {background-color: #FB7FB5; opacity: 0.6}
.groups-tool-item-active {background-color: #FB7FB5;}

/* APOLLO ------------------------- */
.apollo-header, .apollo-item { border: 0; padding: 0; cursor: pointer;  min-height: 39px; }
.apollo-item-static {background-color: #f4f4f5; color: #000;}
.apollo-item-par {background-color: #f4f4f5; color: #000;}
.apollo-header, .apollo-item-impar {background-color: #e6e6e8; color: #000;}
.apollo-item:hover, .apollo-item-active { background-color: #FB7FB5; color: #000; }
.apollo-header p, .apollo-item p { color: #000; margin: 0; font-size: 14px; padding: 11px; }
.apollo-item span.resultRef { color: #000; font-weight: bold; font-size: 14px; }
.apollo-item-count {color: #fff; padding: 0 10px; font-size: 18px; font-weight: 700; background: rgba(0, 0, 0, 0.20); text-align: center; line-height: 39px;}
.apollo-registration-container {float: left; width: 100%}

/* ARCHIVE CONTENT ---------------- */

.archive-wrapper { float: left; width: 1130px; background: #F1F1F2; padding: 15px; }
.archive-wrapper-left { float: left; width: 557px; }
.archive-wrapper-right { float: right; width: 558px; }
.archive-wrapper .breadcrumb { background: #dcebf6; padding: 30px; }
.archive-wrapper .breadcrumb .heading-text { background: #fff; padding: 10px; margin: 0; }
.archive-wrapper .breadcrumb .heading-text span { border: 1px solid #e0f5ff; padding: 0 5px; font-size: 30px; font-weight: 100; color: #7f7f7f; }
.archive-wrapper .archiveResults { float: left; width: 100%; }
.archive-wrapper .archiveResults .heading-text { font-size: 48px; font-weight: 100; padding-left: 40px; margin: 15px 0; color: #7f7f7f; }

.archive-search { display: none; float: left; width: 1130px; background: #f9f9fa; padding: 15px 15px 0; }
.archive-search fieldset { border: 0; margin: 12px 0 10px; padding: 0; }
.archive-search fieldset > div { float: left; margin-right: 30px; }
.archive-search fieldset div:first-child { width: 450px; margin-right: 60px; }
.archive-search fieldset div label { float: right; font-size: 14px; color: #333; line-height: 27px; }
.archive-search fieldset div input[type='text'] { width: 250px; height: 30px; padding: 0 5px; background: #e0e0e1; border: 0; font-size: 13px; color: #999999; }
.archive-search fieldset div input[type='submit'] { width: 85px; height: 30px; padding: 0 5px; margin-top: 30px; background: #e0e0e1; border: 0; font-size: 14px; font-weight: bold; text-transform: uppercase; color: #999999; cursor: pointer; }
.archive-search fieldset div input[type='submit']:hover { color: #000; }
.archive-search fieldset div ul { padding: 0; margin: 0; list-style-type: none; }
.archive-search fieldset div ul li { margin: 4px 0; }
.archive-search fieldset div ul li a { font-size: 14px; color: #777; background: url(../img/search_selected.png) 0 2px no-repeat; padding-left: 25px; }
.archive-search fieldset div ul li a:hover { color: #000; text-decoration: none; }
.archive-search fieldset div ul li a.selected { background-position: 0 -18px; }
.archive-search fieldset div:last-child { float: right; color: #000; font-size: 13px; font-weight: bold; margin-right: 0; }
.archive-search fieldset div:last-child a { color: #000; }
.archive-search fieldset div:last-child a:hover { color: #ccc; text-decoration: none; }

.archive-search-wrapper { float: left; width: 1130px; background: #E6E7DE; padding: 0px; overflow: hidden; }
.archive-search-tool { float: left; width: 214px; background: #97CBC0; padding: 0px; color: #fff; font-size: 14px; margin-left: 10px; margin-top:10px; margin-bottom: 10px; }
.archive-search-tool .noscroll { background: #fff !important; height: 175px !important; }
.archive-search-tool a { color: #fff;}
.archive-search-tool-item {height: 25px; cursor:pointer;}
.archive-search-tool-item-par {background-color: #53C1B1;min-height: 25px; padding-left: 10px; padding-top: 3px; padding-bottom: 3px; cursor:pointer;}
.archive-search-tool-item-par:hover {background-color: #F7931D; opacity: 0.6}
.archive-search-tool-item-impar {background-color: #63B7AD;min-height: 25px; padding-left: 10px;padding-top: 3px; padding-bottom: 3px;cursor:pointer;}
.archive-search-tool-item-impar:hover {background-color: #F7931D; opacity: 0.6}
.archive-search-tool-item-active {background-color: #F7931D;}

.label-deactivate {float: right;font-family: Lato; font-weight: 100; opacity: 0.5; width:20px;}

.item-label-drag { /*width: 100px;*/ position: absolute; z-index: 10000; background-color: #93c7ed; color: #fff; font-size: 16px;padding: 5px;}
.item-label-drag a { color: #fff; font-size: 14px; }

.archive-search-tool-header { }
.archive-search-tool-title {padding: 4px;min-height: 25px; line-height: 25px; font-family: Lato; font-weight: 600;}
.archive-search-tool-add a,
.archive-search-tool-nums { padding: 0px 5px; width:35px;}
.archive-search-tool-add a,
.archive-search-tool-nums { text-align: center; width: 15px; float: right;}
.archive-search-tool-nums { font-size: 11px; padding-top: 8px; height: 26px; width: 34px; background:#14588C; }
.archive-search-tool-add a { text-align: center; font-size: 25px; background-color: #184A70; height: 34px; width: 34px;}
.archive-search-tool-add a:hover { background-color: #6eb3d8; text-decoration: none; }
.archive-search-tool-title { margin-right: 50px; padding-top: 5px; height: 25px;}

.archive-search-tool-add-label { display: none; background-color:  #4da3cf; margin-top: 0px; padding: 5px; text-align: center; }
.archive-search-tool-add-label div { padding: 3px; border: 1px dashed #f9f9fa;}
.archive-search-tool-add-label input { border: 0px; background-color: #4da3cf; color: white; margin-left:-20px; font-size:14px;}

#addCategory, #addGroup { background-color: #63B7AD; opacity:0.6; text-align: center; padding-top: 10px; height: 198px; margin-top:10px;}
#addCategory input, #addGroup input { width: 180px; background-color: #97CBC0; color: white; padding: 5px; border: 1px dashed #f9f9fa; font-size: 14px;margin-top:20px;}
#addCategory a, #addGroup a { font-size: 100px; color: white; font-weight: lighter;}
#addCategory a:hover, #addGroup a:hover  { text-decoration: none; color: #f9f9fa;}

.archive-search-notfound { background-color: #FF9012; height: 198px; padding: 15px; margin-left: 235px;margin-right: 10px;}
.archive-search-notfound p { color: white; font-size: 26px; font-weight: 100;}

.archive-results-default { float: left; display: inline-block; width: 100%; border: 0; padding: 0; cursor: pointer;  min-height: 78px; }
.archive-results-default-in { float: left; display: inline-block; width: 100%; }
.archive-results-default span.resultRef { color: #000; font-weight: bold; font-size: 14px; }
.archive-results-default:hover { opacity: 0.8;  }
.archive-results-default p { margin: 0px; color: #000; margin: 0; font-size: 14px; padding: 11px; }

.archive-results-version-item { border: 0; padding: 0; cursor: pointer;  min-height: 39px;}
.archive-results-version-item-par {background-color: #f4f4f5; color: #000;}
.archive-results-version-item-impar {background-color: #e6e6e8; color: #000;}
.archive-results-version-item-par:hover {background-color: #93c7ed; color: #000;}
.archive-results-version-item-impar:hover {background-color: #93c7ed; color: #000;}
.archive-results-version-item-par-active {background-color: #93c7ed; color: #000;}
.archive-results-version-item p { color: #000; margin: 0; font-size: 14px; padding: 11px; }
.archive-results-version-item span.resultRef { color: #000; font-weight: bold; font-size: 14px; }
.archive-results-version-item-count {color: #fff; padding: 0 10px; font-size: 18px; font-weight: 700; background: rgba(0, 0, 0, 0.20); text-align: center; line-height: 39px;}
.archive-results-version-item-counter {background: rgba(0, 0, 0, 0.20) !important; }
.archive-results-version-item-color {color:#000 !important}
.archive-results-version-item-labels { background-color: #D8D8D8; padding: 5px;}
.archive-results-version-item-labels-show { margin-top: 0px;}


.archive-results-version-item-labels-show span {
	display:inline-block;
	overflow: hidden;
	max-width:430px !important;
	text-overflow: ellipsis;
	text-decoration: none;
	white-space: nowrap;
	position: relative;
	margin-right:10px;
	white-space: nowrap;
	background-color: #93c7ed;
	color: #fff;
	font-size: 16px;
	padding: 5px 5px 5px 5px;
	margin-bottom:3px;
	em {font-style: normal;}
}
.archive-results-version-item-labels-show span.active.longtag	{
	&:hover em  em {
		left:-530px;
		font-style: normal;
		-webkit-transition: 4s ease-in;
		-moz-transition: 4s ease-in;
		-o-transition: 4s ease-in;
		transition: 4s ease-in;
	}
	em em {
		-webkit-transition: 0.5s ease-in;
		-moz-transition: 0.5s ease-in;
		-o-transition: 0.5s ease-in;
		transition: 0.5s ease-in;
		left:0;
	}
	&:hover {
		text-decoration: none  !important;
		em {
			font-style: normal;
			display: inline-block;
			text-decoration: none  !important;
			em {
				display: inline-block;
			}
			}
		strong {
			text-decoration: none  !important;
			}
		}
	em {
		font-style: normal !important;
		em {
			font-style: normal;
			position: relative;
		}
	}
}
.archive-results-version-item-labels-show span.floatLeft,
.archive-results-version-item-labels-show span.active {	padding: 5px 20px 5px 5px !important; }

.archive-results-version-item-labels-show span.inactive { opacity:0.3; }
.archive-results-version-item-labels-show span:last-child  {border-right: 0;}
.archive-results-version-item-labels-show span a {color: #fff; margin-left: 10px; font-weight: bold; position: absolute;right:5px}
.archive-results-version-item-labels-add-area { clear: both; margin-top: 10px; background-color: #fff; border: 2px dashed #455569; color: #313B49; font-size: 16px;}
.archive-results-version-item-labels-add-area-active,
.archive-results-version-item-labels-add-area-hover { background-color: #93c7ed; border-color: #fff; color: #fff; }

.archive-results-version-state { padding: 0; float: right;  }
.archive-results-version-item-state {color: #fff; padding: 0 10px; font-size: 18px; font-weight: 300; background: rgba(255, 255, 255, 0.30); text-align: center; line-height: 39px; text-transform:uppercase; }

.archive-results-item-par {background-color: #e5e5e5; color: #000;}
.archive-results-item-impar {background-color: #d9d9d9; color: #000;}
.archive-results-counter-empty { color: #fff; height: 78px; float: left; width: 5px; margin-right: 10px; font-size: 28px; font-weight: 700; background: #455569; text-align: center; display: inline-table; }
.archive-results-version { padding: 0; width: 44px; height: 39px; float: right; }
.archive-results-version span { height: 39px; }
.archive-results-version-count {color: #fff; padding: 0 10px; font-size: 18px; font-weight: 700; text-align: center; line-height: 39px;}
.archive-results-version-count span { border-bottom: 2px dotted #fff; }
.archive-results-version-lower {font-weight: bold; font-size: 31px; color: #fff; text-align: center; line-height: 39px; }
.archive-results-pagination {background: #ccc;padding: 10px 12px; font-weight: 700; color: #666;}
.archive-results-pagination span { padding: 0 3px; }
.archive-results-pagination a { padding: 0 3px; color: #fff; }
.archive-results-pagination a:hover { background: #ddd; color: #000; }

/* ARCHIVE TABS ---------------- */

.etabs #tab-archive #archivetab { display: inline-block; padding: 12px 13px 12px 10px !important; }
.etabs #tab-archive #archivetab:hover { color: #000; }
.etabs #tab-archive #searchtabar { display: inline-block; background: url('../img/search.png') 0 0 no-repeat; padding: 8px; }
.etabs #tab-archive #searchtabar:hover { background-position: 0 -17px; }

/* MESSAGES BOX ---------------- */

#messageBoxOverMouse { position: absolute; padding: 10px; z-index: 1001; background: #33a142; color: #000; font-size: 15px; opacity: 1; width: 300px; display:none; }
#messageBoxOverMouse p { margin: 0; }
#messageBoxOverMouse p a { color: #fff; text-decoration: underline; }
#messageBoxOverMouse p a:hover { cursor: pointer; text-decoration: none; background: #555; }

/* FORMS ---------------- */

/*
#formLogin { padding-left: 60px; }
#formLogin label { display: block; color: #737677; font-size: 11px; font-weight: 300; }
#formLogin input { display: block; color: #737677; padding: 2px; border: 0px; margin-bottom: 10px; }
#formLogin input.buttonStyle { background-color: #d5d5d7; color: #9fa5a5; border: 0; padding: 10px; font-weight: 300; cursor: pointer; }
#formLogin input.buttonStyle:hover { background-color: #acacb3; color: #fff; }
*/
/* -- LABELS -- (OLD STYLES) new style is 'tag' */
.labels span em {font-style: normal;}
.labels span.longtag {
	display:inline-block;
	overflow: hidden;
	max-width:430px !important;
	text-overflow: ellipsis;
	text-decoration: none;
	white-space: nowrap;
	position: relative;
	background-color: #93c7ed;
	color: #fff;
	font-size: 16px; padding: 5px; margin-right: 5px;  margin-bottom:3px;
	&:hover em  em {
    left:-430px;
    -webkit-transition: 4s ease-in;
    -moz-transition: 4s ease-in;
    -o-transition: 4s ease-in;
    transition: 4s ease-in;
  }
  em {
		display:inline-block;
		position: relative;
		em {
    -webkit-transition: 0.5s ease-in;
    -moz-transition: 0.5s ease-in;
    -o-transition: 0.5s ease-in;
    transition: 0.5s ease-in;
    left:0;
		position: relative;
  }
}
}
.labels span.inactive { opacity:0.3; }
.labels span.floatLeft,
.labels span.active {	padding: 5px 20px 5px 5px !important; }
.labels span a {color: #fff; margin-left: 10px; font-weight: bold;  position: absolute;right:5px}
.labels span.floatLeft {
	white-space: nowrap !important;
	a {
		padding-right:0 !important;
		color: #fff;
		margin-left: 10px;
		margin-right:-15px;
		font-weight: bold;  position: relative;right:0;
	}
}

.labels div {
	display:inline-block;
	text-decoration: none;
	white-space: nowrap;
	position: relative;
	 background-color: #93c7ed; color: #fff; font-size: 16px; padding: 5px; margin-right: 5px; display:inline-block; margin-bottom:3px;}
.labels div.inactive { opacity:0.5; }
.labels div.floatLeft,
.labels div.active {	padding: 5px 20px 5px 5px !important; }
.labels div a {color: #fff; margin-left: 10px; font-weight: bold;  position: absolute;right:5px}

/* HEADER ---------------- */

#topHeader { position: fixed; z-index: 1001; left: 0px; border: 0px solid; width: 100%; min-height: 55px; background: url('../img/shadow-foot-large.gif');background-repeat: repeat-x;}
#topHeaderCenter { position: relative; /*width: 490px;*/ width: 520px; margin: 0 auto; /*overflow: hidden;*/ border: 1; text-align: left; }
#topHeaderCenter .headerButton {
	float: left;
	width: 140px;
	padding: 20px 0;
	margin-left: 10px;
	text-align: center;
	border-left: 0px solid #dedee0;
	border-right: 0px solid #dedee0;
	cursor:pointer;
	text-transform: uppercase;
	font-family: 'Lato', Verdana;
	font-size: 14px;
	font-weight: 300;
	color: #F1F1F2;
}
#topHeaderCenter div:hover { color: #EB4E51; }
#screenMarker { position: absolute; left: 0px; width: 136px; opacity: 0.25; height: 60px; background-color: #E6E7DE; margin-left: 14px; text-align:center; }
/*#botHeader { position: fixed; z-index: 1001; top: 57px; background: url('../img/shadow-1.png') 0 0 no-repeat; width: 1240px; height: 51px; }*/
/*#SCRb { position: fixed; background: url('../img/background_large.gif') -600px 0 repeat-x; width: 160%; height: 100%; bottom: 0; }*/
#SCRb { position: fixed; width: 160%; height: 100%; bottom: 0; }

.topProfilePanel{float: right; margin-right: 20px; width: 158px; height: 69px;background: #00595A;}
.topProfilePanelPicture{float: left; padding: 0px; background: #fff; height: 69px; width:69px;}
.topProfilePanelMenu{float: left; width:89px;}
.topProfilePanelButton{width: 89px; text-align: left; margin-left:5px; color: #fff; padding: 3px;}
.topProfilePanelButton a{color: #fff;}
.topProfilePanelButton a:hover{text-decoration: underline;}

/* FOOTER ---------------- */

#bottomNav { position: fixed; bottom: 0px; height: 26px; width: 100%; margin-top: 40px; text-align:left; background:#666666 url('../img/shadow-foot-1.gif'); z-index: 10000; }
.bottomNavLeft { float: left; padding: 7px 10px; }
.bottomNavRight { float: right; padding: 3px 10px; }
.bottomNavLeft p, .bottomNavRight img { margin: 0; }

.transparent50 {
	filter:alpha(opacity=50);
	-moz-opacity:0.5;
	-khtml-opacity: 0.5;
	opacity: 0.5;
}

.transparent90 {
	filter:alpha(opacity=90);
	-moz-opacity:0.9;
	-khtml-opacity: 0.9;
	opacity: 0.9;
}

.flatBackground {
	background: #f1f1f3;
}

.textHeader {
	font-family: 'Lato', Verdana;
	font-size: 14px;
	font-weight: 300;
	color: #a7a8aa;
}

.textLink {
	font-family: 'Lato', Verdana;
	cursor: pointer;
}

.textLink A{
	text-decoration: underline;
	color: inherit;
	cursor: pointer;
}

.notificationPanel{
	background: #53C0F6;
	color: #fff;
}
