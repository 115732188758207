@import "form-wysiwyg";
@import "form-multiuploader";
@import "form-uploader";
@import "form-calendar";

/* variables */
$color-form-even: #E5E4E3;
$color-form-odd: #F1F1F2;
$color-form-hover: #B9CAE8;

/* general */
.twona-helpers-form {
	padding: 5px 0px;

	.form-text {
		color: $color-black;
		font-family: $font-family-default;
	}

	label {
		color: $color-black;
		margin-bottom: 2px;
		font-family: $font-family-default;
		font-weight: 600;
		font-size: 14px;
		height: 35px;

		// inline (checkbox, select)
		width: 120px;
		display: inline-block;

	}

	input, select {
		&.large { width: 100%; }
		color: $color-black;
		border-width: 0px;
		padding: 2px 10px 2px 10px;
		background-color: $color-white;
		font-family: $font-family-default;
		font-weight: 300;
		font-size: 16px;
		height: 35px;
		//width:100%; // not work for checkbox
		&[disabled="disabled"] {
			color: #999 !important;
			background-color: transparent;
		}
		&.only-numbers{
			width: 40px;
		}

	}

	button {
		&.twona-helpers-form-button-button {
			//@extend .button;
			@extend .bg-c5-light;
			padding: 5px 5px 7px 5px;
			color: $color-white;
			text-align: center;
			border: 0px;
			width: 100%;
			font-family: $font-family-default;
			font-weight: 300;
			font-size: 20px;
			//.span {padding: 5px; }
			&.large { padding: 10px 10px 12px 10px; font-size: 30px }
			&:focus { @extend .bg-c5-dark;}

			// extra colors
			&.blue {background-color: #4DA1AD;}
			&.orange {background-color: #F7931D;}
			&.white {background-color: #FFFFFF;}

			// extra sizes
			&.half-width-button {
				width: 50%;
			}
		}
	}

	&:hover {
		cursor: pointer;
		background-color: $color-form-hover;
	}

}
/* groups */
.twona-helpers-form-group {
	padding: 0px;

	&:hover {
		background-color: transparent;
	}

	.twona-helpers-form-group-header {
		@extend .status-ui-state-default;
		//@extend .bg-c3-light; // couldn't be overwritten

		.status-count-empty { @extend .status-count-empty; }
	}

	.twona-helpers-form-group-area {
		padding: 0px 0px;
		color: $color-black;

		//@extend .list;
		@extend .global-light;

		.twona-helpers-form { // item
			padding: 5px;

			&.twona-helpers-form-button {
				padding: 0px;
			}

			// odd even
			&:nth-child(even) {
				background-color: $color-form-even;
				&:hover {
					cursor: pointer;
					background-color: $color-form-hover;
				}
			}

			/*button {
				margin: -5px;
			}*/
		}
	}
}

/* assets */
.twona-helpers-form-assetselector {
	padding: 5px;
	.twona-helpers-form-assetselector-item {
		opacity: 0.5;
		padding: 5px;
		&:nth-child(odd) {
			background-color: $color-form-odd;
		}
		&:nth-child(even) {
			background-color: $color-form-even;
		}
		&:hover {
			//cursor: pointer;
			//background-color: $color-form-hover;
			opacity: 1;
		}

	}
	// class to selected radio element
	.twona-helpers-form-assetselector-item.twona-helpers-form-assetselector-item-selected {
		background-color: $color-form-hover;
		opacity: 1;
	}

	label {
		width: 300px;
		span{
			padding-left: 10px;
		}
	}

}
/* checkbox */
.twona-helpers-form-checkbox {
	input { height: inherit; }
}

/* select */
.twona-helpers-form-select {
	select {
		height:40px;
		&.small {
			font-size:12px;
			height:20px;
		}
	}
}

/* text */
.twona-helpers-form-text {
	input {
		width: 300px;

		font-style: italic
	}
}
