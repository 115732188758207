/* GENERAL - GENERAL */

@mixin bgopacity($opacity) {
    background: rgba(255, 255, 255, $opacity);
}

.notclickable {cursor: none}
.clickable {cursor: pointer;}
.noFloat {float: none;}
.transparent {background-color:transparent;}
.floatRight {float: right;}
.floatLeft {float: left;}
.hidden {display: none;}
.nomargin {margin: 0px;}
.dimmed {opacity: 0.5}
.center {
    text-align: center;
    vertical-align: center;
}

/* new utils */
.hoverable {
    &.bg-dimmed {
        &:hover {
            @extend .bg-dimmed;
            //background: rgba(255, 255, 255, 0.10);
        }
    }
    &:hover {
        @extend .bg-dimmed;
        //background: rgba(255, 255, 255, 0.30);
    }
}

.bg-soft-dimmed {
    @include bgopacity(0.10);
    //background: rgba(255, 255, 255, 0.10);
}

.bg-dimmed {
    @include bgopacity(0.30);
    //background: rgba(255, 255, 255, 0.30);
}

.bg-hard-dimmed {
    @include bgopacity(0.70);
    //background: rgba(255, 255, 255, 0.70);
}

.zebra {
    &:nth-child(odd) {
        > div:first-of-type {
            background-color: rgba(255, 255, 255, 0.2);

            &.hoverable {
                @extend .hoverable;
            }
        }

        .zebra-item {
            background-color: rgba(255, 255, 255, 0.2);
            &.hoverable {
                @extend .hoverable;
            }
        }
    }
}

.zebra-group {
    &:nth-child(odd) {
        > div:first-of-type {
            background-color: rgba(255, 255, 255, 0.2);

            &.hoverable {
                @extend .hoverable;
            }
        }
    }
}

/* -- GENERAL - PADDING --- */

.padding-small {padding: 5px;}
.padding-regular {padding: 10px;}
.padding-large {padding: 15px;}
.padding-none {padding: 0px !important;}
