/* new style
<p class="tags"><span class="tag">label<a>action icon</a></span></p>
*/

.tag.small {
  margin-top:0 !important;
  line-height: 22px !important}


.filter .tag.longtag {
    overflow: visible;
    text-overflow: inherit;
  max-width:100% !important;
    a{
      right:0;
      width:20px;
      text-align:right;
      cursor: pointer;
      padding-right: 2px;
      position: absolute;
      color: #fff !important;
      font-weight: 500;
  }
 }
 .zebra {
     &:nth-child(odd) {
         > div.related-labels-group:first-of-type {
             background-color: rgba(255, 255, 255, 0.2);
             &.hoverable {
                 @extend .hoverable;
             }
         }
     }
 }
 .related-labels-group  {
   display: table !important;
   min-width:556px;
   min-height:40px;
   span.text-big {
    padding: 5px 10px;
    position: relative;
    display: block;
    min-height:30px;
    top:0px;
    }
   p {
     text-align: left !important;
     line-height:32px !important;
     &.first-p {
      display: table-cell;
      padding-left:0;
      min-width:40px;
      vertical-align: top;
     }
     &.second-p {
       width:100%;
       display: table-cell;
       vertical-align: middle;
       text-align: left !important;
       padding:6px 0 3px 0 !important;
       line-height: 120% !important;
       .tag {
         padding-top:0 !important;
         word-break: break-all;
         line-height: 16px !important;
         padding-bottom: 0 !important;}
     }
   }
 }
 .tag.tablelongtag {
   overflow: hidden;
   max-width:300px !important;
   text-overflow: ellipsis;
   &:hover em  em {
     left:-430px;
     -webkit-transition: 4s ease-in;
     -moz-transition: 4s ease-in;
     -o-transition: 4s ease-in;
     transition: 4s ease-in;
   }
   em em {
     -webkit-transition: 0.5s ease-in;
     -moz-transition: 0.5s ease-in;
     -o-transition: 0.5s ease-in;
     transition: 0.5s ease-in;
     left:0;
   }
 }

.tag.longtag {
  overflow: hidden;
  max-width:430px !important;
  text-overflow: ellipsis;
  &:hover em  em {
    left:-430px;
    -webkit-transition: 4s ease-in;
    -moz-transition: 4s ease-in;
    -o-transition: 4s ease-in;
    transition: 4s ease-in;
  }
  em em {
    -webkit-transition: 0.5s ease-in;
    -moz-transition: 0.5s ease-in;
    -o-transition: 0.5s ease-in;
    transition: 0.5s ease-in;
    left:0;
  }
}

.tag.editmode {
    padding: 5px 20px 5px 10px !important;
}

.tag {
    background-color: #93c7ed;
    color: #fff;
    font-size: 16px;
    //padding: 5px;
    line-height: 23px;
    vertical-align: middle;
    padding: 5px 5px 5px 10px;
    margin-right: 5px;
    display:block;
    margin-top:10px;
    margin-bottom:3px;
    text-transform: none;
    display:inline-block;
    text-decoration: none;
    white-space: nowrap;
    position:relative;
    border-right:5px solid transparent;
    &:last-child {border-right: 0;}
    &:hover {
      text-decoration: none  !important;
      em {
        display: inline-block;
        text-decoration: none  !important;
        em {
          display: inline-block;
        }
        }
      strong {
        text-decoration: none  !important;
        }
      }
    em {
      font-style: normal !important;
      em {
        font-style: normal;
        position: relative;
      }
    }


/*
    strong {
      position: relative;
      width: 100%;
      white-space: nowrap;
    }
    em {
        font-style: normal;
        font-weight: normal;
        cursor: pointer;
        display: block;
        -webkit-transition: 0.5s ease-in;
        -moz-transition: 0.5s ease-in;
        -o-transition: 0.5s ease-in;
        transition: 0.5s ease-in;
    }

    em:hover {
      -webkit-transition: 4s ease-in;
      -moz-transition: 4s ease-in;
      -o-transition: 4s ease-in;
      transition: 4s ease-in;
      text-indent:-200px;
    }
    */
    &.small {
        //padding: 3px;
        padding: 0px 3px;
        line-height: 18px;
        font-size: 12px;
        margin-right: 3px;
    }

    &.inactive {
         opacity:0.5;
    }

    a, a:link, a:visited &{
        right:0;
        width:20px;
        text-align:right;
        cursor: pointer;
        padding-right: 2px;
        position: absolute;
        color: #fff !important;
        font-weight: 500;
    }
}
