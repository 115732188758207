twona-helper-datepicker {
    border: 0;
    font-family: 'Lato', Verdana, Arial, Helvetica, sans-serif;
    font-size: 32px;
    font-weight: 300;
    text-transform: uppercase;
    color:white;

    >div.header {
        text-align: center;
        font-weight: 300;
        font-family: 'Lato', Verdana, Arial, Helvetica, sans-serif;
        font-size: 32px;
        display:flex;
        justify-content:space-between;
        padding-bottom: 15px;
        text-transform: uppercase;
        line-height: 40px;
        position: relative;
        padding-top: 10px;
        >span{
            height: 16px;
            position: relative;
            display: inline-block;
        }
        >span.prev {
            cursor: pointer;
            width: 16px;
            margin-left:20px;
            margin-top:8px;
            background: url(../img/sprite_arrows.png) -1px -1px no-repeat;
        }
        >span.middle{
            width: 70%;

        }
        >span.next {
            cursor: pointer;
            margin-right:20px;
            margin-top:8px;
            width: 16px;
            background: url(../img/sprite_arrows.png) -15px 0 no-repeat;
        }
    }
    >div.week {
        cursor: default;
        max-width: 100%;
        margin: 0 auto;
        padding: 0 2% 0 2%;
        &:first-child {
            border-top:none;
        }

        >span.day {
            display: inline-block;
            font-size: 20px;
            width: 12.2%;
            margin: 5px 1%;
            padding: 1.5% 0;
            text-align: center;

            &:first-child {
                border-left:none;
            }

            &.today {
                font-weight: bold;
            }

            &.different-month {
                color:#C0C0C0;
            }

            &.selected {
                background-color: #E1AB32 !important;
            }

            &.selectable {
                cursor: pointer;
                background: rgba(250, 250, 250, 0.2);

                &:hover {
                    background: rgba(225, 171, 50, 0.4) !important;
                }
            }
        }

        &.names>span {
            font-weight:bold;
            &.day{
                display: inline-block;
                width: 13.19%;
                font-size: 16px;
                padding: 10px 0;
                text-align: center;
                color: #fafafa;
                margin: 0px !important;
            }
        }
    }
    >div.week:last-child{
        padding-bottom: 10px;
    }
}
