$list-buttons-item-height: 20px;
$list-item-height: 30px;
$list-item-padding: 10px;

.list {
	.item {
		padding: $list-item-padding;
		//min-height: $list-item-height;
		cursor: pointer;
		/*background-color: #E9E8E7;*/
		&:nth-child(odd) {
			/*background-color: #E5E4E3;*/
			background-color: rgba(160, 160, 160, 0.1);
		}

		&:hover { cursor: pointer; background-color: #B9CAE8; }

		&.nocursor {
			cursor: none;
			&:hover {
				cursor: auto;
			}
		}

		&.active {background-color: #7CA3DC;}
		&.selected {background-color: #7CA3DC;}
		&.deactive, // old name
		&.inactive { opacity: 0.3;}

		.marker {
			margin-left: -10px;
			margin-top: -10px;
			//height: $list-item-height + 20px;
		}

		p { font-size: 14px; color: #000; line-height: 15px; margin:0px;}
		td {
			padding: 5px;
			min-width: 40px;
			height: $list-item-height;
			&.box {
				@extend .center;
				background-color: rgba(0, 0, 0, 0.1);
			}
		}
		.right { width: 278px; float: right; }
	}

    &.buttons {
        .item {
            height: $list-buttons-item-height;

            button {
				&:first-of-type {
					margin-right: -10px;
				}
                display: table-cell;
                float: right;
                margin: -10px 0px -10px 0px;
                height: $list-buttons-item-height + 20px;
                width: $list-buttons-item-height + 20px;
                display: block;
                border: 0px;
                cursor: pointer;
                font-size: 20px;
                font-weight: 100;
				color: white;
            }
        }
    }

	.marker { color: #fff; float: left; width: 5px; margin-right: 10px; font-size: 28px; font-weight: 700; text-align: center; line-height: 39px; display: inline-table; }

}

table {
	&.list {
		width: 100%;
		border-collapse: collapse;
		.item { cursor:default; }
	}
}
