//*****************************************************************************
//    Project: Twona
//    Title: _tw-header.scss
//    Description: Header styles
//    Page: Generic
//******************************************************************************/
/*.tw-header{
  	display: block;
  	position: fixed;
  	width: 100%;
  	background: #404041;
  	z-index: 999;
  	&__container{
  		display: block;
  		width: 100%;
  		font-size: 0;
  	}
  	&__ctn{
  		display: inline-block;
  		vertical-align: top;
  		&:first-child{
  			width: 210px;
  			padding-left: 50px;
  		}
  		&:nth-child(2){	
  			width: calc(100% - 675px);
  			text-align: center;
  		}
  		&:last-child{
  			width: 415px;
  		}
  	}
  	&__fig{
  		display: block;
    	margin: 0;
    	width: 150px;
  	}
  	&__img{
  		width: 100%;
  	}
  	&__list{
  		display: block;
    	margin: 0;
    	list-style: none;
    	padding-left: 0; 
    	vertical-align: top;
    	text-align: center;
  	}
  	&__item{
  		display: inline-block;
  		vertical-align: top;
  	}
  	&__btn-menu{
  		border: none;
  		background: #404041;
  		color: #F1F1F2;
  		padding: 20px 25px;
  		font-size: 16px;
  		cursor: pointer;
  		&:hover{
  			color: #BF4B51;
  		}
  		&:active{
  			background: #696968;
  		}
  	}
}
*/