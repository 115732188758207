.twona-request {
    .request-plugins-buttons {
      height:auto;
        .right-plugins{
          display:flex;
          flex-wrap:wrap;
          height: 100%;
          padding:0;
          background: transparent !important;
          align-items:center;
          flex-direction: row;
          justify-content:flex-end;
        .archive-results-version.action-request-data-button-2 {
          padding-right: 15px !important;
        }
        .floatLeft {
          img {
            position: relative !important;
            top:6px !important}
        }
        .floatLeft,
        .archive-results-version {
            float:none;
            background: rgba(255,255,255,0.3);
            width: auto !important;
            padding-right: 4px !important;
            margin:0 0 0 0 !important;
            display: block;
        }
      }
    }

    .loading{
        width: 100%;
        text-align: center;
    }

    .twona-request-details {
        .detail {
            display: inline-block;
            width: 49.5%;
            .user {
                p, .avatar {
                    display: inline-block;
                    vertical-align: middle;
                }
                .avatar {
                    height: 40px;
                    width: 40px;
                }
            }
            .editable{
                text-decoration: underline;
            }
        }
    }

    .twona-request-text {
        color: #000;
        font-size: 14px;
        //line-height: 27px;
        margin-bottom: 10px;
        //padding: 5px;
        //border-top: 1px solid grey;
        //border-bottom: 1px solid grey;
        //margin: 0 15px;
        @extend .padding-large;
        p {
            margin: 0;
        }
    }

    /*.twona-request-targets-versions {
        padding-top: 10px;
        padding-bottom: 5px;
    }*/
    .twona-version-item {
        height: 40px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 557px;
        div {
            line-height: 40px;
        }
        .v-number {
            padding-left: 10px;
            padding-right: 10px;
        }

        .v-status {
            padding-left: 10px;
            padding-right: 10px;
            text-transform: uppercase;
            width: 100%;
            max-width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            //position: relative;
            span {
                display: block;
                -webkit-transition: 0.5s ease-in;
                -moz-transition: 0.5s ease-in;
                -o-transition: 0.5s ease-in;
                transition: 0.5s ease-in;
            }

            span:hover{
                -webkit-transition: 4s ease-in;
                -moz-transition: 4s ease-in;
                -o-transition: 4s ease-in;
                transition: 4s ease-in;
                text-indent: -200px;
            }
        }

        .v-date-owner {
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 10px;
            padding-right: 10px;
            width: 100%;

        }
        .v-identifier {
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-left: 10px;
            padding-right: 10px;
            max-width: 80px;
        }
    }

    .status-space {
        width: 200px;
        height: 40px;
        overflow: hidden;
        text-align: right;
    }

    .owner {
        //width: 170px;
        height: 40px;
        text-align: left;
        overflow: hidden;
    }

    .twona-request-information,.twona-request-related-versions {
        .twona-version-list-item {
            .hoverable {
                &:hover {
                    background: transparent;
                }
            }

            .title {
                .twona-version-list-item-idetifier:hover {@extend .bg-soft-dimmed;}
                .twona-version-list-item-owner:hover {@extend .bg-soft-dimmed;}
                .twona-version-list-item-idetifier {
                    background-color: aquamarine;
                    max-width: 80px;
                }
            }

            .status {
                &:hover {@extend .bg-dimmed;}
            }


        }
    }

}

/** old **/
/*.twona-request-view-page {

    * {
        &.editable{
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .twona-request-details{
        left: 10px;
        top: 0px;
        opacity: 1;
        background-color: #e5e5e5;
        .twona-request-details-header {
            .status-info {
                color: black;
                padding: 5px 15px;
                .request-id {
                    color: #000; font-weight: bold; font-size: 14px;
                }
                .request-info {
                    font-size: 14px; color: #333;
                }
                .status-info-row{
                    display: table-row;
                    width: 100%;
                    .status-info-cell{
                        display: table-cell;
                        vertical-align: middle;
                        width: 240px;
                        padding: 0px;
                        border: 0px;
                        p {
                            margin: 0px;
                        }
                    }
                    .status-info-avatar {
                        display: table-cell;
                        padding: 0px;
                        height: 39px;
                        width: 39px;
                        img {
                            display: block;
                            width: 100%;
                            height: 100%;
                        }

                    }
                }
                .status-info-version {
                    padding: 0; width: 44px; display: table-cell;
                    .status-count {
                        color: #fff; padding: 0 10px; font-size: 18px; font-weight: 700; background: rgba(160, 160, 160, 0.2); text-align: center; line-height: 39px;
                    }
                }
                .status-info-date {
                    font-size: 10px;
                }
            }
        }
    }

    .twona-request-view-info {
        color: #000;
        font-size: 14px;
        line-height: 27px;
        padding: 10px 0px;
        border-top: 1px solid grey;
        border-bottom: 1px solid grey;
        margin: 0 15px;
        .bold {
            font-weight: bold;
        }
    }

    .attachments-content {

        .status-info {
            color: black;
            display: table-cell;
            padding: 5px 15px;
            width: 483px;
            .status-info-file {
                cursor: pointer;
                display: block;
            }
            .status-info-date {
                font-size: 10px;
            }
        }
        .status-file {
            display: table-cell;
            padding-top: 10px;
            width: 44px;  //same as numbers of version, version filetype,
            padding-bottom: 10px;
            background-color: #999999;
            vertical-align: middle;
            .status-filetype{
                text-transform: uppercase;
                color: #fff;
                padding: 0;
                font-size: 12px;
                text-align: center;
                //line-height: 47px;
                font-weight: 700;
            }
        }

        .attachments-add-more-files {
            padding: 0px;
        }
    }

    .twona-request-view-versions {
        display: block;
        .twona-request-view-versions-item{
            width: 100%;
            background-color: #f4f4f5;
            color: #000;
            &:nth-child(odd) {
                background-color: #e6e6e8;
                color: #000;
            }
            &:hover {
                background-color: #93c7ed;
                color: #000;
            }
            .versions-empty {
                padding: 5px;
                padding-left: 10px;
                background-color: #fff;
                font-size: 14px;
                font-family: Lato;
                font-weight:300;
                color: #000;
            }
        }

    }


    .twona-request-version-details{
        left: 10px;
        top: 0px;
        opacity: 1;
        background-color: #e5e5e5;
        .twona-request-version-details-header {
            display: table-row;
            .status-info {
                color: black;
                display: table-cell;
                padding: 5px 15px;
                width: 483px;
                .request-id {
                    color: #000; font-weight: bold; font-size: 14px;
                }
                .request-info {
                    font-size: 14px; color: #333;
                }

            }
            .status-info-version {
                padding: 0; width: 44px; display: table-cell;
                .status-count {
                    color: #fff; padding: 0 10px; font-size: 18px; font-weight: 700; background: rgba(160, 160, 160, 0.2); text-align: center; line-height: 39px;
                }
            }
            .status-info-date {
                font-size: 10px;
            }
        }

        .twona-request-version-details-files {

        }
    }


}



.twona-view-conversation {
}*/
