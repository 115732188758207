.twona-status-view-panels-workflow {

    .twona-status-view-panels-status-container {
        min-height: 40px; //from status_workflows.php line 100
        width: 557px;
        background-color: #E6E7DE;
        //border: 2px dashed #888;
        //background-color: #ddd;

        .status-placeholder {
            display: block;
            height:100px;
            width:500px;
            background-color: rgba(152, 207, 255, 0.2);
            margin: 5px;

        }

        .twona-status-view-panels-status {
            display: block;

            .status-view-panels-status {
                min-height: 39px;
                display: block;
                //@extend .status-ui-widget;
                background-color: rgba(215, 215, 215, 0.5);

                &.ui-sortable-helper {
                    background-color: rgba(215, 215, 215, 0.5);
                }

                .status-container{
                    @extend .status-ui-state-default;
                }
                // request placeholder drop
                .request-item {
                    display: block;
                    height:100px;
                    background-color: rgba(152, 207, 255, 0.2);
                    margin: 5px;
                }

                .status-view-panels-request-container {
                    min-height: 40px; // for remain empty on drag last element
                    line-height: 0;
                    //@extend .list;
                    //left: 10px;
                    max-height: 400px;
                    overflow-y: auto;
                    //margin-top: -10px;
                    padding: 0px;
                    margin: 0px;

                    /* old css from status-ui-widget-content*/
                    @extend .status-ui-widget-content;

                    /* hide scroll */
                    // &::-webkit-scrollbar {
                    //     display: none;  /* Safari and Chrome */
                    // }

                    &.allowed {
                        //border: 2px dashed #888;
                        //background-color: #ddd;
                    }

                    &.not-allowed {
                        opacity: 0.5;
                        filter: alpha(opacity=50); /* For IE8 and earlier */
                        .request-item {
                            display: none;
                        }
                    }

                    &.no-order {
                        .status-view-panels-request-header {
                            cursor: default !important;
                        }
                    }

                    .status-view-panels-request-container-info{
                        line-height: 30px;
                        padding: 5px;
                        margin: 5px;
                        background-color: rgba(160, 160, 160, 0.1);
                        color: #999;
                    }

                    // request
                    .status-view-panels-request {
                        display: block;
                        padding: 5px;
                        margin: 5px;
                        &:nth-child(odd) {
                			/*background-color: #E5E4E3;*/
                			background-color: rgba(160, 160, 160, 0.1);
                		}

                        &.today {
                            background-color: rgba(247, 230, 198, 0.5);
                            &:nth-child(odd) {
                    			background-color: rgba(247, 230, 198, 0.8);
                    		}
                        }

                        &.assigned {
                            background-color: rgba(99, 183, 173, 0.2);
                            &:nth-child(odd) {
                    			background-color: rgba(99, 183, 173, 0.3);
                    		}
                        }

                        &.ui-sortable-helper {
                            background-color: rgba(215, 215, 215, 0.5);
                        }

                        p {
                            line-height: 20px;
                            display: inherit;
                            margin: 0px;
                            vertical-align: middle;
                            a { cursor: pointer; }

                            img {
                                margin-top: -3px;
                                display: inline-block;
                                vertical-align: middle;
                            }

                            &.user {
                                margin-top: 3px;
                            }
                        }

                        //@extend .item;
                        .status-view-panels-request-header {
                            //padding: 0 5px;
                            //font-size: 14px;
                            display:flex;
                            justify-content: space-between;
                            cursor: move;
                            //padding-bottom: 20px;
                            min-height: 50px;
                            .panels-version-list {
                              max-width:400px;}
                            .panels-version {
                              display:flex;
                              max-width:140px;
                              justify-content:flex-end;
                            }

                            // version number

                            .status-view-panels-request-version {
                                color: #fff;
                                font-size: 21px;
                                font-weight: 700;
                                display:block;
                                margin-left:5px;
                                padding:0 5px ;
                                //background: rgba(160, 160, 160, 0.2);
                                text-align: center;
                                float: none !important;
                                //height: 40px;
                                line-height: 40px;
                                height: 40px;
                                width: auto !important;
                                //margin-top: -20px;
                                &.panels-version-target {
                                    background: rgba(120, 120, 120, 0.2);
                                }
                                &.panels-version-origin {
                                    background: rgba(180, 180, 180, 0.2);
                                }
                            }

                            .tag {
                                margin-right: 0px;
                            }
                        }

                        .status-view-panels-request-content {
                            margin-top: 5px;
                            display: block;
                            height: 42px;
                            position: relative;

                            p {
                                font-size: 12px;
                                color: #666;
                            }

                            .info {
                                float: left;
                                //width: 168px;
                                margin-right: 5px;
                                img{
                                    float: left;
                                    margin-right: 5px;
                                }

                                &.date {
                                    width: 135px;
                                }
                                &.user {
                                    width: 185px;
                                }
                            }

                            .badge {
                                position: absolute;
                                left: 504px;
                                top: 0px;
                                width: 30px;
                                height: 30px;

                                &.status_update{
                                    background: url('../img/asset_badge_status_update.png') no-repeat;
                                    background-size: 30px 30px;
                                }

                                &.save{
                                    background: url('../img/asset_badge_save.png') no-repeat;
                                    background-size: 30px 30px;
                                }

                                &.labels_add{
                                    background: url('../img/asset_badge_labels_add.png') no-repeat;
                                    background-size: 30px 30px;
                                }

                                &.labels_remove{
                                    background: url('../img/asset_badge_labels_remove.png') no-repeat;
                                    background-size: 30px 30px;
                                }

                                &.user_assign{
                                    background: url('../img/asset_badge_user_assign.png') no-repeat;
                                    background-size: 30px 30px;
                                }
                            }

                            span.status-view-panels-request-assign {
                                text-decoration: underline;
                            }

                            .assignto {
                                //text-align: center;
                                /*p {
                                    line-height: 40px;
                                    vertical-align: middle;
                                }*/
                                span { color: #666; }

                                &.twona-helpers-form {
                                    select {
                                        max-width: 160px;
                                        background-color: #fff;
                                    }

                                    &:hover {
                                		background-color:transparent;
                                	}
                                }
                            }
                        }
                    }

                    .requests-draggable-handler {
                        min-width: 15px;
                        min-height: 60px;
                        display: inline-block;
                        vertical-align: top;
                    }

            /*
            .bubble
    {
    position: relative;
    width: 15px;
    height: 15px;
    padding: 1px;
    background: #FF0000;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    }
    */

            /*.requests-status-info {
                //@extend .status-info;
                width: 472px;
                display: inline-block;

                .requests-ref {
                    color: #000;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 39px;
                }

                .request-info {
                    font-size: 14px;
                    color: #333;
                    line-height: 39px;
                }
            }*/

            /*.requests-status-version {
                @extend .status-version;
                width: 25px;
                display: inline-block;
                color: #fff;
                padding: 0 10px;
                font-size: 18px;
                font-weight: 700;
                background: rgba(160,160,160,0.2);
                text-align: center;
                line-height: 39px;

            }*/

                }

            }
        }
    }
}
