.twona-helpers-form-calendar {
    clear: both;
    padding: 10px;
    border: 0;
    font-size: 14px;
    font-weight: 300;
    &:hover {
		background-color: transparent;
	}
    datepicker a, [datepicker] a, .datepicker a{
      color:inherit;
      text-decoration:none;
    }
    datepicker a:hover, [datepicker] a:hover, .datepicker a:hover{
      text-decoration:none;
    }
    datepicker select, datepicker select:focus, datepicker select:hover,
    .datepicker select, .datepicker select:focus, .datepicker select:hover,
    [datepicker] select, [datepicker] select:focus, [datepicker] select:hover{
      width:100%;
       overflow: hidden;
       background:none;
       color:#fff;
       border: 0;
       margin-top:5px;
    }
    datepicker, .datepicker, [datepicker],
    ._720kb-datepicker-calendar-header,
    ._720kb-datepicker-calendar-body,
    ._720kb-datepicker-calendar-days-header,
    ._720kb-datepicker-calendar-years-pagination-pages {
      font-family: 'Lato', Verdana, Arial, Helvetica, sans-serif;
      font-size: 32px;
      text-transform: uppercase;
      width: 100%;
      margin: 0 auto;
      clear: right;
    }
    ._720kb-datepicker-calendar {
      background: transparent;
      color: #fff;
      z-index: 0;
      min-width: 220px;
      margin: 0 auto;
      width: 100%;
      margin-left:0;
      padding: 0 0 2% 0;
    }
    ._720kb-datepicker-calendar._720kb-datepicker-open,._720kb-datepicker-calendar._720kb-datepicker-forced-to-open {
      visibility: visible;
    }
    ._720kb-datepicker-calendar-header {
      text-align: center;
      font-weight: 300;
      font-family: 'Lato', Verdana, Arial, Helvetica, sans-serif;
      font-size: 32px;
      text-transform: uppercase;
      line-height: 40px;
      position: relative;
    }
    ._720kb-datepicker-calendar-header-left,
    ._720kb-datepicker-calendar-header-middle,
    ._720kb-datepicker-calendar-header-right {
      width: 15%;
      position: relative;
      display: inline-block;
    }
    ._720kb-datepicker-calendar-header-left ._720kb-datepicker-calendar-month-button span,
    ._720kb-datepicker-calendar-header-right ._720kb-datepicker-calendar-month-button span{
        display: block;
        position: absolute;
        margin-left: -8px;
        margin-top: -8px;
        width: 16px;
        height: 16px;
        text-indent: -99999px;
        overflow: hidden;
        top: 50%;
    }

    ._720kb-datepicker-calendar-header-left{
        ._720kb-datepicker-calendar-month-button{
            position: relative;
            .triangle{
                left: 0px;
                background: url(../img/sprite_arrows.png) -1px -1px no-repeat;
                &:hover {
                    background-position:-1px -19px;
                }
            }
        }
    }
    ._720kb-datepicker-calendar-header-right{
        ._720kb-datepicker-calendar-month-button{
            position: relative;
            .triangle{
                 right: 0px;
                 background: url(../img/sprite_arrows.png) -15px 0 no-repeat;
                 &:hover {
                     background-position:-15px -18px;
                }
            }
        }
    }
    ._720kb-datepicker-calendar-header-middle {
        width: 70%;
    }

    ._720kb-datepicker-calendar-header-closed-pagination::after {
      content: " \25BE";
    }

    ._720kb-datepicker-calendar-header-opened-pagination::after {
      content: " \25BE";
      margin-left: 4px;
      position: relative;
      bottom: -3px;
      display:inline-block;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    ._720kb-datepicker-calendar-body {
      width: 96%;
      margin: 2%;
      text-align: center;
    }
    ._720kb-datepicker-calendar-day {
        display: inline-block;
        cursor: pointer;
        font-size: 20px;
        width: 12.2%;
        margin:5px 1%;
        padding: 1.5% 0;
    }
    ._720kb-datepicker-calendar-day:hover,
    ._720kb-datepicker-calendar-day._720kb-datepicker-active {
        background-color: #E1AB32 !important;
    }
    ._720kb-datepicker-calendar-header a, ._720kb-datepicker-calendar-header a:hover {
        text-decoration:none;
        padding:3% 9% 4% 9%;
        color:rgba(250,250,250,250);
    }
    ._720kb-datepicker-calendar-header a:hover {
        color:rgba(250,250,250,250);
    }
    ._720kb-datepicker-calendar-month {
        color:#fff;
    }
    ._720kb-datepicker-calendar-month span {
        color:rgba(250,250,250,250);
    }
    ._720kb-datepicker-calendar-month a span  i {
        font-style: normal;
    }
    ._720kb-datepicker-calendar-month a, ._720kb-datepicker-calendar-month a:hover {
        padding: 3px;
        margin-left:1%;
    }
    ._720kb-datepicker-calendar-years-pagination{
        padding:2% 0 0 0;
        clear: right;
        width: 100%;
    }
    ._720kb-datepicker-calendar-years-pagination a, ._720kb-datepicker-calendar-years-pagination a:hover {
        font-size:12px;
        padding:0 7px;
        margin:3px 1% 0 1%;
        line-height: 20px;
        display: inline-block;
    }
    ._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active {
        color:rgba(250,250,250,250);
        font-weight: 500;
        background: rgba(255, 255, 255, 0.45);
    }
    ._720kb-datepicker-calendar-years-pagination-pages a,._720kb-datepicker-calendar-years-pagination-pages a:hover{
        padding:5px 10px;
    }
    ._720kb-datepicker-calendar-days-header{
        max-width: 100%;
        margin:0 auto;
        padding:0 2% 0 2%;
    }
    ._720kb-datepicker-calendar-days-header div{
        display: inline-block;
        width: 14.18%;
        font-size: 16px;
        padding:10px 0;
        text-align: center;
        color:rgba(250,250,250,250);
    }
    ._720kb-datepicker-calendar-days
    ._720kb-datepicker-default-button{
        bottom:-0.5px;
    }
    ._720kb-datepicker-default-button{
        padding:0 4.5px;
    }
    ._720kb-datepicker-calendar-header-middle._720kb-datepicker-mobile-item{
        width:95%;
        float:none;
        margin:0 auto;
    }
    ._720kb-datepicker-item-hidden{
        visibility:hidden;
    }
    ._720kb-datepicker-calendar-day._720kb-datepicker-disabled,
    ._720kb-datepicker-calendar-day._720kb-datepicker-disabled:hover,
    ._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled,
    ._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-disabled:hover,
    ._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled,
    ._720kb-datepicker-calendar-years-pagination a._720kb-datepicker-active._720kb-datepicker-disabled:hover{
        color:rgba(250,250,250,250);
        background: rgba(25,2,0,0.02);
        cursor: default;
        /*visibility: hidden;*/
    }
    ._720kb-datepicker-calendar-day._720kb-datepicker-today {
        box-shadow: 0px 0px 0px 1px red;
        font-weight: bold;
    }
}
