/**
*
*	Yellow Text
*	========================================
*	This is a pre-made theme for the Yellow
*	text editor and it's not needed to run
*	the plugin.
*
*/
.twona-helpers-form-wysiwyg {
    height: auto;
    min-height: 380px;
    //height: 330px; // solved! keep height auto. Without overlapping the next group. 
    margin-left: -2px;
    .js-editor-container {
      //margin-top: 0.5em;
      //border: 1px solid #c9c8c8 !important;
      border: 0px  !important;
      border-radius: 0px;
     // border-radius: 3px;
      //box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    }

    /**
    *
    *	Buttons
    *	========================================
    *	This section styles the buttons and the
    *	button bar, that holds all the buttons.
    *
    */
    .js-editor-buttons {
     // border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      border: 1px solid #c9c8c8 !important;
      color: #666;
      @extend .global-light;
      //background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #e8e8e8), color-stop(100%, #d2d1d1));
      //background-image: -webkit-linear-gradient(#e8e8e8, #d2d1d1);
      //background-image: -moz-linear-gradient(#e8e8e8, #d2d1d1);
      //background-image: -o-linear-gradient(#e8e8e8, #d2d1d1);
      //background-image: linear-gradient(#e8e8e8, #d2d1d1);
      //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
      margin-bottom: 5px;
    }

    .js-editor-iframe {
        border: 1px solid #c9c8c8 !important;
        background-color: #fff;
        box-shadow: inset 0 0 0px;
    }

    .js-editor-buttons > a {
      display: block;
      float: left;
      width: 16px;
      height: 16px;
      margin: 8px;
      font: 16px Georgia;
      text-align: center;
      text-transform: uppercase;
      text-shadow: 0 1px #fff;
      cursor: pointer;
    }

    .bold {
      font-weight: bold;
    }

    .underline {
      text-decoration: underline;
    }

    .italic {
      font-style: italic;
    }

    .strikethrough {
      text-decoration: line-through;
    }

    .icon-sprite, .JustifyLeft, .JustifyCenter, .JustifyRight, .InsertUnorderedList, .InsertOrderedList, .image, .link {
      background: url('../img/icons-yellow-text.png') no-repeat;
    }

    .JustifyLeft,
    .JustifyCenter,
    .JustifyRight,
    .InsertUnorderedList,
    .InsertOrderedList,
    .image,
    .link {
      margin-top: 9px;
      text-indent: -99999em;
    }

    .JustifyLeft {
      background-position: 0 -276px;
    }

    .JustifyCenter {
      background-position: 0 -184px;
    }

    .JustifyRight {
      background-position: 0 -230px;
    }

    .InsertUnorderedList {
      background-position: 0 -138px;
    }

    .InsertOrderedList {
      background-position: 0 -46px;
    }

    .image {
      background-position: 0 -92px;
    }

    .link {
      background-position: 0 0;
    }

    .js-editor-buttons a:first-child {
      margin-left: 35px;
    }

    .js-editor-buttons a:nth-child(4) {
      margin-right: 35px;
    }

    .js-editor-buttons a:nth-child(7) {
      margin-right: 35px;
    }

    .js-editor-buttons a:nth-child(9) {
      margin-right: 35px;
    }
}
