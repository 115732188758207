@charset "UTF-8";

// ::-webkit-scrollbar-track {
//     display: none;
//     border-color: transparent;
//     background-color:transparent;
//   }
//   ::-webkit-scrollbar * {
//     background:transparent;
//   }
//   ::-webkit-scrollbar {
//     width:7px;
//     min-width:7px;
//     height:7px;
//     min-height:7px;
//   }
//   ::-webkit-scrollbar-corner {
//     background-color:transparent;
//   }
//   ::-webkit-scrollbar-thumb {
//     border-radius:10px;
//     background-color:#666;
//     -webkit-box-shadow: inset 0 0 0 ;
//   }

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

/**
 * VARIABLES
 **/

/* fonts */
$font-family-default: 'Lato',  'Helvetica Neue', Verdana, Arial, sans-serif;

/* colors */
$color-black: #000;
$color-white: #fff;
