$block-header-height: 40px;
$block-padding: 10px;

select.chitform {
max-width:140px !important  
}
.block-header {
    border: 0;
    padding: 0;
    margin: 0;

    color: #fff;
    font-size: 14px;
    //background-color:  #BEC0C6;
    /*&.zebra {
        &:nth-child(odd) {
            div {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }*/

    p {
        color: #fff;
        margin: 0;
        line-height: $block-header-height;
        min-width: $block-header-height - ($block-padding*2);
        text-transform:uppercase;
        padding-left: $block-padding;
        padding-right: $block-padding;
        vertical-align: middle;
        text-align: center;
        display: inline-block;
    }

    .right {
        float: right;
    }

    div {
        height: $block-header-height;
    }
    .avatar {
        display: inline-block;
        vertical-align: middle;
        height: 40px;
        width: 40px;
    }
}

.block-content {
    margin: 0;
    padding: 0;

    > p:first-of-type { // hack for real life
        display: inline-block;
    }

    /*&.padding {
        padding-left: $block-padding;
        padding-right: $block-padding;
    }*/
}

/*
.archive-results-version-state { padding: 0; float: right;  }
.archive-results-version-item-state {color: #fff; padding: 0 10px; font-size: 18px; font-weight: 100; background: rgba(255, 255, 255, 0.30); text-align: center; line-height: 39px; text-transform:uppercase; }

.status-ui-state-default { border: 0; padding: 0; cursor: pointer; }
.status-ui-state-default:hover { opacity: 0.8; }
.status-ui-state-default.notclickable { cursor: auto; }
.status-ui-state-default.notclickable:hover { opacity: 1; }
.status-ui-state-default .status-count { color: #fff; float: left; width: 45px; margin-right: 10px; font-size: 28px; font-weight: 700; background: rgba(255, 255, 255, 0.40); text-align: center; line-height: 39px; display: inline-table; }
.status-ui-state-default .status-count-empty { color: #fff; float: left; width: 5px; margin-right: 10px; font-size: 28px; font-weight: 700; background: rgba(255, 255, 255, 0.40); text-align: center; line-height: 39px; display: inline-table; }
.status-ui-state-default p { color: #fff; margin: 0; font-size: 14px; padding: 11px; text-transform: uppercase; }
.status-ui-state-default.nolink { cursor: auto; }
.status-ui-state-default.nolink:hover { opacity: 1; }
*/
