p {
    &.text-capital {
        @extend .text-capital;
    }
    /*&.thin {
        font-size: 18px;
        font-weight: 100;
    }

    &.big {
        font-size: 28px;
        font-weight: 700;
    }

    &.upper {
        text-transform: uppercase;
    }

    &.capital {
        text-transform: capitalize;
    }*/
}

.text-thin {
    font-size: 18px;
    font-weight: 300;
}

.text-big {
    font-size: 28px;
    font-weight: 700;
}

.text-upper {
    text-transform: uppercase;
}

.text-capital {
    text-transform: capitalize;
}
