

/*
.twona-list {
	.twona-list-item {

		&:nth-child(odd) {
			background-color: rgba(160, 160, 160, 0.1);
		}
        &:nth-child(even) {
			background-color: rgba(160, 160, 160, 0.3);
		}
        .small-padding{
            padding: 5px;
        }
        .medium-padding{
            padding: 10px;
        }
        .large-padding{
            padding: 15px;
        }
    }
}
*/
