//*****************************************************************************
//    Project: Twona
//    Title: _tw-workload.scss
//    Description: Workload styles
//    Page: Home
//******************************************************************************/
.tw-workload{
	display: block;
	width: 100%;
	max-width: 1160px;
	cursor: default;
	&__svg{
		width: 1130px;
		cursor: default;
		height: 100%;
		&--month{
			min-height: 315px;
			.tw-workload__rect{
					fill: rgb(241, 241, 242);
					&--white{
							fill: rgb(241, 241, 242);
					}
			}
		}
		&--week{
				min-height: 285px;
				.tw-workload__rect{
						fill: rgb(241, 241, 242);
						&--white{
								fill: rgb(244, 245, 247);
						}
				}
			}
	}
	&__rect{
		width: calc(100% / 7);
		height: 100%;
	}
	&__ctn-btn{
		display: block;
		width: 100%;
		font-size: 0;
		margin-bottom: 10px;
	}
	&__btn{
		background: #4DA1AD;
		color: white;
		font-size: 20px;
		text-align: center;
		width: 50%;
    	border: none;
    	padding: 10px;
	    font-weight: 300;
		font-family: "Lato",Verdana,Arial,Helvetica,sans-serif;
    	cursor: pointer;
    	&:hover{
    		background: #78cad6;
    	}
	}
	&__box{
		width: calc(100% / 7);
		height: 48px;
		fill: #f1f0f2;
		&--white{
			fill: rgb(244, 245, 247);
		}
		&--grey{
			fill: rgb(232, 232, 232);
		}
	}
	&__circle{
		fill: #CACACA;
		&--value{
			fill: #F7931D;
		}
	}
	&__line{
		stroke-width: 6;
		stroke: #F7931D;
	}
	&__text{
		font-family: sans-serif;
		font-size: 12px;
		text-anchor: middle;
		fill: white;
		&--small{
			font-size: 12px;
			fill: gray;
		}
		&--gray{
			fill: gray;
		}
		&--bold{
			font-weight: bold;
			font-size: 12px;
			font-family: sans-serif;
			text-transform: uppercase;
		}
		&--center{
			display: block;
    		width: 100%;
    		padding: 10px 0;
    		text-align: center;
    		background: rgb(247, 147, 29);
				cursor: default;
		}
	}
	&__control{
		width: 100%;
		font-size: 0;
		padding: 0;
	}
	&__control-item{
		display: inline-block;
		font-size: 12px;
		vertical-align: top;
		width: calc((100% / 7) * 5);
		min-height: 34px;
		background: #f7931d;
		&:first-child{
			width: calc(100% / 7);
		}
		&:last-child{
			width: calc(100% / 7);
		}
	}
	&__link{
		font-size: 12px;
		font-family: sans-serif;
		color: white !important;
		cursor: pointer;
		text-align: center;
		display: block;
		padding: 10px 0;
		background: rgb(247, 147, 29);
		&:hover{
			color: white;
			text-decoration: none;
			background: #f9b059;
		}
	}
}
