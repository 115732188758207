/* labels */
.twona-helpers-labels-action {
    display: none;
}

.fix-long-word {
word-break: break-all !important; 
}

.labels-search {
  line-height:170% !important;
.tag {
display: inline-block;
margin-top:0 !important;
padding-top:2px !important;
padding-bottom:2px !important;
padding-left:2px !important;
padding-right:2px !important;
margin-bottom:3px !important;
line-height: 22px !important;
}
}
.labels-inside .tag {
margin-top:-3px !important;
padding-top:2px !important;
margin-bottom:10px;
padding-bottom:2px !important;
line-height: 20px !important;
}



.twona-helpers-labels-container{
    float: left;
    width: 1130px;
    background: #f9f9fa;
    padding: 15px;
    @extend .twona-helpers-labels;
    .no-margin{
        margin: 0px !important;
    }
}
.twona-helpers-labels {
    padding: 15px;
    .twona-helpers-labels-search {
        padding-right: 20px;
        //height: 40px;
        width: 100%;

        .twona-helpers-labels-search-input {
            overflow: hidden;
        }
        input {
            width: 100%;
            &:placeholder-show {
                font-style: italic;
            }
        }

        select.input-text {
            // remove chrome select style
            -webkit-appearance: none;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            border-radius: 0px;

            // button style
            @extend .bg-c5-light;
            text-align: center;
            border: 0px;
            width: 100%;
            font-family: $font-family-default;
            font-weight: 300;
            font-size: 16px;
            font-style: normal;
            color: $color-white;

            height: 39px; // fix height
            width: 150px;
            float: right;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .twona-helpers-labels-holder {
        margin-top: 5px;
        max-height: 300px;
        overflow-y: auto;
        //background-color: #fff;
        color: $color-black;
        background-color: rgba(255,255,255,0.4);
        .tag {
          top:-2px;
          padding: 5px 5px 5px 6px !important;
          line-height:16px !important;
          margin-bottom:0 !important;
          display: inline-block !important;
        }
        &:empty {
            display: none;
        }

        p { margin: 0px; padding: 5px 0px 5px 0px; font-size: 16px; }
    }

    .action-twona-helpers-labels-template-label{
        display: none;
    }
}
