.profile-avatar-edit-area{
    width: 325px;
    background-image: url('../img/ajax-loader-1.gif');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    position: relative;
    margin: auto;
    display: block;
    .action-profile-avatar-add {
        position: absolute;
        width: 325px;
        top: 285px;
        left: 10px;
        padding: 10px 0px;
        background-color:rgba(0,0,0,0.5);
        height: 30px;
        font-size: 24px;
        font-weight: 100;

        &:hover {
            background-color:rgba(0,0,0,0.7);
            cursor: pointer;
        }
    }
}

.margin-avatar img{
    position: relative;
    right: 12%;
}

.profileAvatar{
    height: 400px;
    width: 400px;
}

.edit-profile{
    margin-left: 0px;
}

.margin-loading{
    margin: 0 auto;
    display: block;
}
