.twona-status-view-table-workflow {
    color: #000;

    .empty {
        background-color: rgba(160,160,160,0.1);
        text-align: center;
        padding: 8px 0px;
    }

/*    table {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        * {
            box-sizing: inherit;
        }
        td, th {
            padding: 3px;
            text-align: center;
            //border:1px solid #888;
            &.left {
                text-align: left;
            }
        }

        thead > tr, tbody > tr, tfoot > tr {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
        }

        thead, tfoot {
            flex-shrink: 0;
        }

        thead {
            display: flex;
            flex-direction: column;
            tr {
                th {
                    text-transform: uppercase;
                    width: 20%;
                    display: inline-block;
                    padding: 3px;
                }
            }
        }

        tbody {
            background-color: #E6E7DE;
            max-height: 500px;
            overflow-y: scroll;
            display: inline-block;

            tr {
                width: 100%;

                &.ui-sortable-helper {
                    display: table;
                    opacity: 0.7;
                }

                &:nth-child(odd) {
                    background-color: rgba(160, 160, 160, 0.1);
                }

                &:hover {
                    background-color: rgba(160, 160, 160, 0.3);
                }

                &.sorteable-placeholder {
                    height: 40px;
                    background-color: rgba(152, 207, 255, 0.2);
                }

                td {
                    width: 20%;
                    cursor: move;
                    //display: inline-block;
                    padding: 3px;
                    li
                    a {
                        cursor: pointer;
                    }
                }
            }
        }
    }
*/

    .badge {
        float:right;
        min-width: 20px;
        min-height: 20px;
        &.status_update{
            background: url('../img/asset_badge_status_update.png') no-repeat;
            background-size: 20px 20px;
        }

        &.save{
            background: url('../img/asset_badge_save.png') no-repeat;
            background-size: 20px 20px;
        }

        &.labels_add{
            background: url('../img/asset_badge_labels_add.png') no-repeat;
            background-size: 20px 20px;
        }

        &.labels_remove{
            background: url('../img/asset_badge_labels_remove.png') no-repeat;
            background-size: 20px 20px;
        }

        &.user_assign{
            background: url('../img/asset_badge_user_assign.png') no-repeat;
            background-size: 20px 20px;
        }
    }
    .table-body {
        max-height: 500px;
        overflow-y: scroll;
        td {
            cursor: move;
            padding: 3px;
            a {
                cursor: pointer;
            }

            .tag {
                margin-right: 0px;
                margin-bottom: 0px;
            }
        }
        tr {
            &.ui-sortable-helper {
                opacity: 0.7;
            }

            &:nth-child(odd) {
                background-color: rgba(160, 160, 160, 0.1);
            }

            &.today {
                background-color: rgba(247, 230, 198, 0.5);
                &:nth-child(odd) {
                    background-color: rgba(247, 230, 198, 0.8);
                }
            }

            &.assigned {
                background-color: rgba(99, 183, 173, 0.2);
                &:nth-child(odd) {
                    background-color: rgba(99, 183, 173, 0.3);
                }
            }

            &:hover {
                background-color: rgba(160, 160, 160, 0.3);
            }

            &.sorteable-placeholder {
                height: 40px;
                background-color: rgba(152, 207, 255, 0.2);
            }

        }

        &.no-order {
            td {
                cursor: default;
                a {
                    cursor: pointer;
                }
            }
        }
    }
    .table-head {
        display: block;
        padding-right: 10px;
        th {
            text-transform: uppercase;
            padding: 3px;
            cursor: pointer;
            .table-ordered {
                position: relative;
                .table-order-asc:after{
                    font-size: 95%;
                    //content: '\2227';   // BLACK UP-POINTING TRIANGLE
                    content: '\25B2';
                    position: absolute;
                    padding-left: 2px;
                }
                .table-order-desc:after{
                    font-size: 95%;
                    //content: '\2228'; // BLACK DOWN-POINTING TRIANGLE
                    content: '\25BC';
                    position: absolute;
                    padding-left: 2px;
                }
            }
        }
    }
    .table-body, .table-head {
        td, th {
            padding: 3px;
            text-align: center;
            //border:1px solid #888;
            &.left {
                text-align: left;
            }
            &.tiny {
                width: 50px;
            }
            &.small {
                width: 80px;
            }
            &.med {
                width: 105px;
            }
            &.status {
                width: 160px;
            }
            &.big {
                width: 350px;
            }
        }
    }

}
