/* multiuploader */
.twona-helpers-form-multiuploader {

    .twona-helpers-form-multiuploader-files {
        margin-top: 5px;
    }

    .twona-files-list-item-progressbar.fail {
        color: #fff;
        .ui-progressbar-value { background-color: red; }
    }
}

/* file info */
.twona-helpers-form-fileinfo-item {
    /* sortable handler */
    .action-sortable-fileinfo-item-handler {
        @extend .floatLeft;
        //@extend .action-sortable-handler;
        background: url(../img/asset_icon_draggable.png) repeat;
        cursor: ns-resize;
        z-index: 1000;
        height: 40px;
        width: 15px;
        //position: absolute;
    }

    .twona-helpers-form-fileinfo-item-name {
        margin-left: 15px; // STYLE  NEW to let space to sortable handler
    }
}
