.twona-search {
    &.twona-search-filters {
        .filter {
            padding: 7px 7px 5px 7px;
            margin: 5px;
            background-color:rgba(255, 255, 255, 1);
            width:1095px;

            &.inactive {
                opacity: 0.5;
                //background-color:rgba(255, 255, 255, 0.5);
            }

            span {
                &.title {
                    margin-right: 25px;
                }

                &.tag {
                    &.uncolored {
                        color: #000;
                        background-color: transparent;
                    }
                }
            }

            a {
                &.close {
                    &:hover {
                        text-decoration: none;
                        font-weight: bold;
                    }

                    float: right;
                    margin-top: 3px;
                    margin-right: 3px;
                }
            }
        }
    }
}
