/* uploader */
.twona-helpers-form-uploader {
	input {
		border-width: inherit;
		background-color: inherit;
	}

	.twona-files-list-item-progressbar.fail {
        color: #fff;
        .ui-progressbar-value { background-color: red; }
    }
}
